import dayjs from "dayjs";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { useGetEventsQuery } from "../../../services/event.api";

interface ComponentProps {
  className?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const EventSelect: React.FC<TExtendedProps> = ({ className, ...props }) => {
  const { data: event } = useGetEventsQuery({});

  return (
    <div className={`${className} flex flex-col`}>
      <Field
        {...props}
        className={`w-full py-2 border rounded px-3`}
        as="select"
        name="eventId"
      >
        <option>-- Select Event --</option>
        {event &&
          event.data
            .filter(
              (data) =>
                dayjs(new Date()) <= dayjs(data.dateTime) &&
                data.isActive === true
            )
            .map((event, index) => (
              <option key={index} value={event.id}>
                {event.name}
              </option>
            ))}
      </Field>
      <ErrorMessage name="eventId">
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default EventSelect;
