// src/utils/logger.ts
import {
  CloudWatchLogsClient,
  PutLogEventsCommand,
} from "@aws-sdk/client-cloudwatch-logs";

export type LogLevel = "info" | "warn" | "error" | "debug";

interface LoggerConfig {
  level: LogLevel;
  useCloudWatch: boolean;
  logGroupName?: string;
  logStreamName?: string;
}

class Logger {
  private config: LoggerConfig;
  private cloudWatchClient: CloudWatchLogsClient | null = null;
  private nextSequenceToken: string | undefined;

  constructor(config: LoggerConfig) {
    this.config = config;

    if (config.useCloudWatch) {
      this.cloudWatchClient = new CloudWatchLogsClient({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY!,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY!,
        },
      });
    }
  }

  private async logToCloudWatch(level: LogLevel, message: string) {
    if (!this.config.useCloudWatch || !this.cloudWatchClient) return;

    const logEvent = {
      timestamp: Date.now(),
      message: `[${level.toUpperCase()}] ${message}`,
    };

    const params = {
      logGroupName: this.config.logGroupName!,
      logStreamName: this.config.logStreamName!,
      logEvents: [logEvent],
      sequenceToken: this.nextSequenceToken,
    };

    try {
      const command = new PutLogEventsCommand(params);
      const response = await this.cloudWatchClient.send(command);
      this.nextSequenceToken = response.nextSequenceToken;
    } catch (error) {
      console.error("Failed to log to CloudWatch:", error);
    }
  }

  private logToConsole(level: LogLevel, message: string) {
    const formattedMessage = `[${level.toUpperCase()}] ${message}`;
    switch (level) {
      case "info":
        console.info(formattedMessage);
        break;
      case "warn":
        console.warn(formattedMessage);
        break;
      case "error":
        console.error(formattedMessage);
        break;
      case "debug":
        console.debug(formattedMessage);
        break;
      default:
        console.log(formattedMessage);
        break;
    }
  }

  public log(level: LogLevel, message: string) {
    // Only log messages at or above the current level
    const levels = ["debug", "info", "warn", "error"];
    if (levels.indexOf(level) < levels.indexOf(this.config.level)) return;

    if (this.config.useCloudWatch) {
      this.logToCloudWatch(level, message);
    } else {
      this.logToConsole(level, message);
    }
  }

  public info(message: string) {
    this.log("info", message);
  }

  public warn(message: string) {
    this.log("warn", message);
  }

  public error(message: string) {
    this.log("error", message);
  }

  public debug(message: string) {
    this.log("debug", message);
  }
}

export default Logger;
