import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import {
  CreateDeal,
  CreateDealDetail,
  CreateDealVariant,
  CreateDealVariantAvailability,
  Deal,
  DealDetail,
  DealImage,
  DealVariant,
  DealVariantAvailability,
  UpdateDeal,
  UpdateDealVariant,
  UpdateDealVariantAvailability,
} from "../models/deal.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Deals {
  data: Deal[];
  skip: number;
  take: number;
  count: number;
}

interface DealsQuery {
  skip?: number;
  take?: number;
  search?: string;
  status?: string;
}

interface DealVariants {
  data: DealVariant[];
  skip: number;
  take: number;
  count: number;
}

interface DealDetails {
  data: DealDetail[];
  skip: number;
  take: number;
  count: number;
}

interface DealVariantAvailabilities {
  data: DealVariantAvailability[];
  skip: number;
  take: number;
  count: number;
}

interface DealVariantAvailabilitiesQuery {
  dealId: string;
  variantId: string;
  skip?: number;
  take?: number;
  search?: string;
}

interface DealImages {
  data: DealImage[];
  skip: number;
  take: number;
  count: number;
}

interface DealVariantsQuery {
  dealId: string;
  skip?: number;
  take?: number;
  search?: string;
}

export const dealApi = createApi({
  reducerPath: "dealApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/deals",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: [
    "Deals",
    "Deal",
    "DealVariant",
    "DealVariants",
    "DealDetail",
    "DealDetails",
    "DealImages",
    "DealVariantAvailabilities",
    "DealVariantAvailability",
  ],
  endpoints: (builder) => ({
    createDeal: builder.mutation<Deal, CreateDeal>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Deals"],
    }),
    getDeals: builder.query<Deals, DealsQuery>({
      query: ({ take, skip, search }) => ({
        url: "/",
        params: { skip, take, search },
      }),
      providesTags: ["Deals"],
    }),
    getDeal: builder.query<Deal, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Deal"],
    }),
    updateDeal: builder.mutation<Deal, UpdateDeal>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Deals", "Deal"],
    }),

    deleteDeal: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Deals"],
    }),
    uploadDealImage: builder.mutation<DealImage, { id: string; file: File }>({
      query: ({ id, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/${id}/images/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["DealImages"],
    }),
    setPrimaryDealImage: builder.mutation<
      void,
      { dealId: string; imageId: string }
    >({
      query: ({ dealId, imageId }) => ({
        url: `/${dealId}/images/${imageId}/set-primary`,
        method: "PATCH",
      }),
      invalidatesTags: ["DealImages"],
    }),
    getDealImages: builder.query<
      DealImages,
      { id: string; skip?: number; take?: number }
    >({
      query: ({ id, skip, take }) => {
        const params = new URLSearchParams();
        if (skip !== undefined) params.append("skip", skip.toString());
        if (take !== undefined) params.append("take", take.toString());

        return {
          url: `/${id}/images?${params.toString()}`,
        };
      },
      providesTags: ["DealImages"],
    }),
    deleteDealImage: builder.mutation<
      void,
      { dealId: string; imageId: string }
    >({
      query: ({ dealId, imageId }) => ({
        url: `/${dealId}/images/${imageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DealImages"],
    }),
    // Deal Variants
    createDealVariant: builder.mutation<DealVariant, CreateDealVariant>({
      query: ({ dealId, ...body }) => ({
        url: `/${dealId}/variants`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DealVariants"],
    }),
    getDealVariants: builder.query<DealVariants, DealVariantsQuery>({
      query: ({ dealId, take, skip, search }) => ({
        url: `/${dealId}/variants`,
        params: { skip, take, search },
      }),
      providesTags: ["DealVariants"],
    }),
    getDealVariant: builder.query<
      DealVariant,
      { dealId: string; variantId: string }
    >({
      query: ({ dealId, variantId }) => ({
        url: `/${dealId}/variants/${variantId}`,
      }),
      providesTags: ["DealVariant"],
    }),
    updateDealVariant: builder.mutation<
      DealVariant,
      { id: string } & UpdateDealVariant
    >({
      query: ({ id, dealId, ...body }) => ({
        url: `/${dealId}/variants/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["DealVariant", "Deal"],
    }),
    deleteDealVariant: builder.mutation<void, { dealId: string; id: string }>({
      query: ({ id, dealId }) => ({
        url: `/${dealId}/variants/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DealVariants", "Deal"],
    }),

    uploadDealVariantImage: builder.mutation<
      DealVariant,
      { variantId: string; file: File }
    >({
      query: ({ variantId, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/variants/${variantId}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["DealVariants"],
    }),
    setPrimaryDealVariantImage: builder.mutation<
      void,
      { variantId: string; imageId: string }
    >({
      query: ({ variantId, imageId }) => ({
        url: `/variants/${variantId}/images/${imageId}/set-primary`,
        method: "PATCH",
      }),
      invalidatesTags: ["DealVariants"],
    }),
    deleteDealVariantImage: builder.mutation<
      void,
      { variantId: string; imageId: string }
    >({
      query: ({ variantId, imageId }) => ({
        url: `/variants/${variantId}/images/${imageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DealVariants"],
    }),

    // Deal Details
    createDealDetail: builder.mutation<DealDetail, CreateDealDetail>({
      query: ({ dealId, ...body }) => ({
        url: `/${dealId}/details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DealDetails"],
    }),
    updateDealDetail: builder.mutation<
      DealDetail,
      { id: string } & CreateDealDetail
    >({
      query: ({ id, dealId, ...body }) => ({
        url: `/${dealId}/details/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Deal"],
    }),
    getDealDetails: builder.query<DealDetails, string>({
      query: (dealId) => ({
        url: `/${dealId}/details`,
      }),
      providesTags: ["DealDetails"],
    }),
    deleteDealDetail: builder.mutation<void, { id: string; dealId: string }>({
      query: ({ id, dealId }) => ({
        url: `/${dealId}/details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DealDetails"],
    }),
    createDealVariantAvailability: builder.mutation<
      DealVariantAvailability,
      { dealId: string; variantId: string; data: CreateDealVariantAvailability }
    >({
      query: ({ dealId, variantId, data }) => {
        const formattedData = {
          ...data,
          date: data.date ? new Date(data.date).toISOString() : undefined,
          startDate: data.startDate
            ? new Date(data.startDate).toISOString()
            : undefined,
          endDate: data.endDate
            ? new Date(data.endDate).toISOString()
            : undefined,
        };

        return {
          url: `/${dealId}/deal-variants/${variantId}/deal-variant-availabilities`,
          method: "POST",
          body: formattedData,
        };
      },
      invalidatesTags: ["DealVariantAvailabilities"],
    }),
    getDealVariantAvailabilities: builder.query<
      DealVariantAvailabilities,
      DealVariantAvailabilitiesQuery
    >({
      query: ({ dealId, variantId, skip, take, search }) => ({
        url: `/${dealId}/deal-variants/${variantId}/deal-variant-availabilities`,
        params: { skip, take, search },
      }),
      providesTags: ["DealVariantAvailabilities"],
    }),
    getDealVariantAvailability: builder.query<
      DealVariantAvailabilities,
      {
        dealId: string;
        variantId: string;
        id: string;
      }
    >({
      query: ({ dealId, variantId, id }) => ({
        url: `/${dealId}/deal-variants/${variantId}/deal-variant-availabilities/${id}`,
      }),
      providesTags: ["DealVariantAvailabilities"],
    }),
    updateDealVariantAvailability: builder.mutation<
      DealVariantAvailability,
      {
        dealId: string;
        variantId: string;
        id: string;
        data: UpdateDealVariantAvailability;
      }
    >({
      query: ({ dealId, variantId, id, data }) => {
        const formattedData = {
          ...data,
          date: data.date ? new Date(data.date).toISOString() : undefined,
          startDate: data.startDate
            ? new Date(data.startDate).toISOString()
            : undefined,
          endDate: data.endDate
            ? new Date(data.endDate).toISOString()
            : undefined,
        };

        return {
          url: `/${dealId}/deal-variants/${variantId}/deal-variant-availabilities/${id}`,
          method: "PATCH",
          body: formattedData,
        };
      },
      invalidatesTags: ["DealVariantAvailabilities"],
    }),
    deleteDealVariantAvailability: builder.mutation<
      void,
      { dealId: string; variantId: string; id: string }
    >({
      query: ({ dealId, variantId, id }) => ({
        url: `/${dealId}/deal-variants/${variantId}/deal-variant-availabilities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DealVariantAvailabilities"],
    }),
  }),
});

export const {
  useCreateDealMutation,
  useGetDealsQuery,
  useGetDealQuery,
  useUpdateDealMutation,
  useDeleteDealMutation,
  useUploadDealImageMutation,
  useDeleteDealImageMutation,
  useGetDealImagesQuery,
  useSetPrimaryDealImageMutation,
  useUploadDealVariantImageMutation,
  useSetPrimaryDealVariantImageMutation,
  useDeleteDealVariantImageMutation,
  useCreateDealVariantMutation,
  useGetDealVariantsQuery,
  useGetDealVariantQuery,
  useUpdateDealVariantMutation,
  useDeleteDealVariantMutation,
  useCreateDealDetailMutation,
  useUpdateDealDetailMutation,
  useDeleteDealDetailMutation,
  useGetDealDetailsQuery,
  useCreateDealVariantAvailabilityMutation,
  useGetDealVariantAvailabilitiesQuery,
  useGetDealVariantAvailabilityQuery,
  useUpdateDealVariantAvailabilityMutation,
  useDeleteDealVariantAvailabilityMutation,
} = dealApi;
