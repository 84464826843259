import * as React from "react";
import { Button } from "../../components/ui/button";

import { ScrollArea } from "../../components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  useDeleteDealMutation,
  useGetDealsQuery,
} from "../../services/deal.api";

import { useNavigate } from "react-router-dom";
import Placeholder from "../../assets/images/placeholder.jpg";
import { DeleteDialog } from "./DeleteDiaglog";

export default function DealList() {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="flex justify-between">
        <h1 className="mb-6 text-3xl font-bold">Deals Management</h1>
        <Button onClick={() => navigate("/deals/create")}>Create Deal</Button>
      </div>
      <Tabs defaultValue="active" className="w-full">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="active">Active Deals</TabsTrigger>
          <TabsTrigger value="draft">Draft Deals</TabsTrigger>
          <TabsTrigger value="expired">Expired Deals</TabsTrigger>
        </TabsList>
        <TabsContent value="active">
          <DealsList status="active" />
        </TabsContent>
        <TabsContent value="draft">
          <DealsList status="draft" />
        </TabsContent>
        <TabsContent value="expired">
          <DealsList status="expired" />
        </TabsContent>
      </Tabs>
    </div>
  );
}

function DealsList({ status }: { status: "active" | "draft" | "expired" }) {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetDealsQuery({
    status,
  });
  const [deleteDeal] = useDeleteDealMutation();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedDealId, setSelectedDealId] = React.useState<string | null>(
    null
  );

  if (isLoading) return <div>Loading deals...</div>;
  if (error) return <div>Failed to load deals</div>;

  const handleOpenDialog = (id: string) => {
    setSelectedDealId(id);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedDealId(null);
  };

  const handleDeleteConfirm = () => {
    if (selectedDealId) {
      deleteDeal(selectedDealId)
        .unwrap()
        .catch(() => alert("Failed to delete the deal"))
        .finally(() => handleCloseDialog());
    }
  };

  return (
    <ScrollArea className="h-[600px] w-full rounded-md border">
      <Table>
        <TableCaption>A list of your {status} deals.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Image</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Category</TableHead>
            <TableHead>Variants</TableHead>
            <TableHead>Start Date</TableHead>
            <TableHead>End Date</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.data.map((deal) => {
            const primaryImage = deal.dealImages?.find(
              (image) => image.isPrimary
            );

            const image = primaryImage
              ? process.env?.REACT_APP_IMAGE_BASE_URL +
                primaryImage.image.fileName
              : Placeholder;
            return (
              <TableRow key={deal.id}>
                <TableCell>
                  <img
                    src={image}
                    alt="Deal"
                    className="object-cover w-16 h-16 rounded"
                  />
                </TableCell>
                <TableCell className="font-medium">{deal.name}</TableCell>
                <TableCell>{deal.category.name}</TableCell>
                <TableCell>{deal.dealVariants?.length} variants</TableCell>
                <TableCell>
                  {new Date(deal.startDate).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {new Date(deal.endDate).toLocaleDateString()}
                </TableCell>
                <TableCell className="text-right">
                  <Button
                    variant="outline"
                    size="sm"
                    className="mr-2"
                    onClick={() => navigate(`/deals/${deal.id}`)}
                  >
                    View
                  </Button>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => handleOpenDialog(deal.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <DeleteDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleDeleteConfirm}
        title="Delete Deal"
        description="Are you sure you want to delete this deal? This action cannot be undone."
      />
    </ScrollArea>
  );
}
