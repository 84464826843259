import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "../features/Auth/authSlice";
import { carouselApi, categoryApi } from "../services";
import { artistInquiryApi } from "../services/artist-inquiry.api";
import { authApi } from "../services/auth.api";
import { brandApi } from "../services/brand.api";
import { conversationApi } from "../services/conversation.api";
import { dashboardApi } from "../services/dashboard.api";
import { emailMarketingApi } from "../services/email-marketing.api";
import { eventGroupApi } from "../services/event-group.api";
import { eventTicketApi } from "../services/event-ticket.api";
import { eventVoucherApi } from "../services/event-voucher.api";
import { orderDetailsApi } from "../services/order-details.api";
import { orderApi } from "../services/order.api";
import { organizationApi } from "../services/organization.api";
import { performerApi } from "../services/performer.api";
import { permissionApi } from "../services/permission.api";
import { productCategoryApi } from "../services/product-category.api";
import { productOrderApi } from "../services/product-order.api";
import { productVariantApi } from "../services/product-variant.api";
import { productApi } from "../services/product.api";
import { resellApi } from "../services/resell.api";
import { roleApi } from "../services/role.api";
import { socialMediaApi } from "../services/social-media.api";
import { ticketClaimsApi } from "../services/ticket-claim.api";
import { ticketApi } from "../services/ticket.api";
import { userRoleApi } from "../services/user-role.api";
import { userApi } from "../services/user.api";
import { venueApi } from "../services/venue.api";
import { voucherApi } from "../services/voucher.api";
import { withdrawApi } from "../services/withdraw.api";
import { dealApi } from "./../services/deal.api";
import { eventApi } from "./../services/event.api";
import { genreApi } from "./../services/genre.api";
import { groupApi } from "./../services/group.api";
import { songApi } from "./../services/song.api";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [orderDetailsApi.reducerPath]: orderDetailsApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [eventTicketApi.reducerPath]: eventTicketApi.reducer,
    [venueApi.reducerPath]: venueApi.reducer,
    [performerApi.reducerPath]: performerApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [ticketClaimsApi.reducerPath]: ticketClaimsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [carouselApi.reducerPath]: carouselApi.reducer,
    [voucherApi.reducerPath]: voucherApi.reducer,
    [songApi.reducerPath]: songApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [genreApi.reducerPath]: genreApi.reducer,
    [artistInquiryApi.reducerPath]: artistInquiryApi.reducer,
    [userRoleApi.reducerPath]: userRoleApi.reducer,
    [eventGroupApi.reducerPath]: eventGroupApi.reducer,
    [withdrawApi.reducerPath]: withdrawApi.reducer,
    [eventVoucherApi.reducerPath]: eventVoucherApi.reducer,
    [emailMarketingApi.reducerPath]: emailMarketingApi.reducer,
    [conversationApi.reducerPath]: conversationApi.reducer,
    [productCategoryApi.reducerPath]: productCategoryApi.reducer,
    [productVariantApi.reducerPath]: productVariantApi.reducer,
    [productOrderApi.reducerPath]: productOrderApi.reducer,
    [socialMediaApi.reducerPath]: socialMediaApi.reducer,
    [resellApi.reducerPath]: resellApi.reducer,
    [dealApi.reducerPath]: dealApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      orderApi.middleware,
      orderDetailsApi.middleware,
      organizationApi.middleware,
      eventApi.middleware,
      eventTicketApi.middleware,
      venueApi.middleware,
      performerApi.middleware,
      groupApi.middleware,
      dashboardApi.middleware,
      ticketApi.middleware,
      ticketClaimsApi.middleware,
      productApi.middleware,
      categoryApi.middleware,
      roleApi.middleware,
      permissionApi.middleware,
      carouselApi.middleware,
      voucherApi.middleware,
      songApi.middleware,
      brandApi.middleware,
      genreApi.middleware,
      artistInquiryApi.middleware,
      userRoleApi.middleware,
      eventGroupApi.middleware,
      withdrawApi.middleware,
      eventVoucherApi.middleware,
      emailMarketingApi.middleware,
      conversationApi.middleware,
      productCategoryApi.middleware,
      productVariantApi.middleware,
      productOrderApi.middleware,
      socialMediaApi.middleware,
      resellApi.middleware,
      dealApi.middleware
    ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
