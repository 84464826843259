import { useGetDashboardQuery } from "../../../services/dashboard.api";
import OverviewCard from "./OverviewCard";

const Overview = () => {
  const { data, isLoading } = useGetDashboardQuery();
  if (isLoading) return <h2>Loading...</h2>;
  if (!data) return <h2>Something went wrong...</h2>;
  return (
    <div className="grid flex-grow grid-cols-1 gap-2 lg:gap-8 lg:grid-cols-3">
      <h2 className="flex items-center text-5xl font-semibold uppercase lg:col-span-3 text-lightBlue-500">
        Overview
      </h2>

      <OverviewCard
        title="User"
        data={data.overview.user.count}
        content="Total Number of Users"
        link="user"
      />
      <OverviewCard
        title="Event"
        data={data.overview.event.count}
        content="Total Number of Events"
        link="event"
      />
      <OverviewCard
        title="Venue"
        data={data.overview.venue.count}
        content="Total Number of Venues"
        link="venues"
      />
      <OverviewCard
        title="Organzation"
        data={data.overview.organization.count}
        content="Total Number of Organizations"
        link="organization"
      />
      <OverviewCard
        title="Performer"
        data={data.overview.performer.count}
        content="Total Number of Performers"
        link="performer"
      />
      <OverviewCard
        title="Group"
        data={data.overview.group.count}
        content="Total Number of Groups"
        link="group"
      />
    </div>
  );
};

export default Overview;
