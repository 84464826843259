import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import { CreateResell, Resell, UpdateResell } from "../models/resell.model"; // Import the correct Resell model

export interface CustomError {
  data: {
    message: [string];
  };
}

interface ResellList {
  data: Resell[];
  skip: number;
  take: number;
  count: number;
}

interface ResellQuery {
  skip?: number;
  take?: number;
  search?: string;
  listingType?: string;
}

export const resellApi = createApi({
  reducerPath: "resellApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/tnsell", // Ensure base URL points to 'resell'
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Resell", "ResellList"],
  endpoints: (builder) => ({
    createResell: builder.mutation<Resell, CreateResell>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ResellList"],
    }),
    getResells: builder.query<ResellList, ResellQuery>({
      query: ({ take, skip, search, listingType }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
            listingType,
          },
        };
      },
      providesTags: ["ResellList"],
    }),
    getResell: builder.query<Resell, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Resell"],
    }),
    updateResell: builder.mutation<Resell, UpdateResell>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ResellList", "Resell"],
    }),

    deleteResell: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ResellList"],
    }),
  }),
});

export const {
  useCreateResellMutation,
  useGetResellQuery,
  useGetResellsQuery,
  useUpdateResellMutation,
  useDeleteResellMutation,
} = resellApi;
