import React from "react";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateWithdrawMutation,
} from "../../../services/withdraw.api";
import * as Yup from "yup";
import { ProductSelect } from "../../Product";
import OrganizationSelect from "../../Organization/OrganizationSelect";
import { useGetOrganizationEventsQuery } from "../../../services/organization.api";
import { Event } from "../../../models/event.model";
import { useGetOrdersByEventQuery } from "../../../services/order.api";
import { Order } from "../../../models/order.model";

interface FormValues {
  organizationId: string;
  orderIds: string[];
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateWithdraw: React.FC<ComponentProps> = ({ setOpen }) => {
  const [organizationId, setOrganizationId] = React.useState("");
  const [eventId, setEventId] = React.useState("");
  const { data: eventData } = useGetOrganizationEventsQuery({
    id: organizationId,
  });
  const { data: eventOrderData } = useGetOrdersByEventQuery({
    id: eventId,
    isWithdraw: true,
  });
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateWithdrawMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    organizationId: "",
    orderIds: [],
  };

  const validationSchema = Yup.object().shape({
    organizationId: Yup.string().required("Name is required."),
    orderIds: Yup.array(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create({
      ...values,
    });
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, values }) => {
        return (
          <Form
            onChange={(event: any) => {
              if (event.target.name === "organizationId") {
                setOrganizationId(event.target.value);
              }
              if (event.target.name === "eventId") {
                setEventId(event.target.value);
              }
              console.log("event", event);
            }}
          >
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="organizationId">Organization</Label>
                <OrganizationSelect />
                <Label htmlFor="product">Event</Label>
                <Field
                  className={`py-2 border rounded `}
                  as="select"
                  name="eventId"
                >
                  <option hidden>-- Select Event --</option>
                  {eventData &&
                    eventData.data.map((event: Event, index: number) => (
                      <option key={index} value={event.id}>
                        {event.name}
                      </option>
                    ))}
                </Field>
                <Label htmlFor="orderIds">Orders</Label>
                {eventOrderData &&
                  eventOrderData.data.map((order: Order, index: number) => (
                    <div className="flex col-span-2" key={order.id}>
                      <Field type="checkbox" name="orderIds" value={order.id} />
                      <div className="ml-2">{order.orderNumber}</div>
                      <div className="ml-2">P {order.total}</div>
                    </div>
                  ))}
                <ErrorMessage name="eventId">
                  {(message) => <span className="text-red-500">{message}</span>}
                </ErrorMessage>
              </div>

              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Add
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
