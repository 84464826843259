"use client";

import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../components/ui/collapsible";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

// Mock data
const mockVenue = {
  id: "1",
  name: "Madison Square Garden",
  city: "New York",
  country: "USA",
};

type Template = {
  id: string;
  name: string;
  description: string;
};

type SectionGroup = {
  id: string;
  name: string;
  description: string;
  templateId: string;
};

type Section = {
  id: string;
  name: string;
  description: string;
  sectionGroupId: string;
};

type Row = {
  id: string;
  name: string;
  sectionId: string;
};

type Seat = {
  id: string;
  number: number;
  rowId: string;
};

export default function SelectedVenuePage() {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [sectionGroups, setSectionGroups] = useState<SectionGroup[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [rows, setRows] = useState<Row[]>([]);
  const [seats, setSeats] = useState<Seat[]>([]);

  const handleTemplateSubmit = (data: Omit<Template, "id">) => {
    const newTemplate = { ...data, id: Date.now().toString() };
    setTemplates([...templates, newTemplate]);
  };

  const handleSectionGroupSubmit = (
    templateId: string,
    data: Omit<SectionGroup, "id" | "templateId">
  ) => {
    const newSectionGroup = { ...data, id: Date.now().toString(), templateId };
    setSectionGroups([...sectionGroups, newSectionGroup]);
  };

  const handleSectionSubmit = (
    sectionGroupId: string,
    data: Omit<Section, "id" | "sectionGroupId">
  ) => {
    const newSection = { ...data, id: Date.now().toString(), sectionGroupId };
    setSections([...sections, newSection]);
  };

  const handleRowSubmit = (
    sectionId: string,
    data: Omit<Row, "id" | "sectionId">
  ) => {
    const newRow = { ...data, id: Date.now().toString(), sectionId };
    setRows([...rows, newRow]);
  };

  const handleSeatSubmit = (
    rowId: string,
    data: { startNumber: number; endNumber: number }
  ) => {
    const newSeats = Array.from(
      { length: data.endNumber - data.startNumber + 1 },
      (_, index) => ({
        id: Date.now().toString() + index,
        number: data.startNumber + index,
        rowId,
      })
    );
    setSeats([...seats, ...newSeats]);
  };

  return (
    <div className="container p-4 mx-auto space-y-6">
      <h1 className="text-2xl font-bold">Manage Venue: {mockVenue.name}</h1>

      <Dialog>
        <DialogTrigger asChild>
          <Button>Create Venue Template</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Venue Template</DialogTitle>
          </DialogHeader>
          <VenueTemplateForm onSubmit={handleTemplateSubmit} />
        </DialogContent>
      </Dialog>

      {templates.map((template) => (
        <Collapsible key={template.id} className="space-y-2">
          <CollapsibleTrigger className="flex items-center justify-between w-full p-2 bg-gray-100 rounded-md">
            <span>{template.name}</span>
            <ChevronRight className="w-4 h-4" />
          </CollapsibleTrigger>
          <CollapsibleContent className="pl-4 space-y-2">
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline">Create Section Group</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Create Section Group</DialogTitle>
                </DialogHeader>
                <SectionGroupForm
                  onSubmit={(data) =>
                    handleSectionGroupSubmit(template.id, data)
                  }
                />
              </DialogContent>
            </Dialog>

            {sectionGroups
              .filter((sg) => sg.templateId === template.id)
              .map((sectionGroup) => (
                <Collapsible key={sectionGroup.id} className="space-y-2">
                  <CollapsibleTrigger className="flex items-center justify-between w-full p-2 bg-gray-200 rounded-md">
                    <span>{sectionGroup.name}</span>
                    <ChevronRight className="w-4 h-4" />
                  </CollapsibleTrigger>
                  <CollapsibleContent className="pl-4 space-y-2">
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="outline">Create Section</Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Create Section</DialogTitle>
                        </DialogHeader>
                        <SectionForm
                          onSubmit={(data) =>
                            handleSectionSubmit(sectionGroup.id, data)
                          }
                        />
                      </DialogContent>
                    </Dialog>

                    {sections
                      .filter((s) => s.sectionGroupId === sectionGroup.id)
                      .map((section) => (
                        <Collapsible key={section.id} className="space-y-2">
                          <CollapsibleTrigger className="flex items-center justify-between w-full p-2 bg-gray-300 rounded-md">
                            <span>{section.name}</span>
                            <ChevronRight className="w-4 h-4" />
                          </CollapsibleTrigger>
                          <CollapsibleContent className="pl-4 space-y-2">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button variant="outline">Create Row</Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Create Row</DialogTitle>
                                </DialogHeader>
                                <RowForm
                                  onSubmit={(data) =>
                                    handleRowSubmit(section.id, data)
                                  }
                                />
                              </DialogContent>
                            </Dialog>

                            {rows
                              .filter((r) => r.sectionId === section.id)
                              .map((row) => (
                                <Collapsible key={row.id} className="space-y-2">
                                  <CollapsibleTrigger className="flex items-center justify-between w-full p-2 bg-gray-400 rounded-md">
                                    <span>{row.name}</span>
                                    <ChevronRight className="w-4 h-4" />
                                  </CollapsibleTrigger>
                                  <CollapsibleContent className="pl-4 space-y-2">
                                    <Dialog>
                                      <DialogTrigger asChild>
                                        <Button variant="outline">
                                          Create Seats
                                        </Button>
                                      </DialogTrigger>
                                      <DialogContent>
                                        <DialogHeader>
                                          <DialogTitle>
                                            Create Seats
                                          </DialogTitle>
                                        </DialogHeader>
                                        <SeatForm
                                          onSubmit={(data) =>
                                            handleSeatSubmit(row.id, data)
                                          }
                                        />
                                      </DialogContent>
                                    </Dialog>

                                    <Table>
                                      <TableHeader>
                                        <TableRow>
                                          <TableHead>Seat Number</TableHead>
                                        </TableRow>
                                      </TableHeader>
                                      <TableBody>
                                        {seats
                                          .filter((s) => s.rowId === row.id)
                                          .map((seat) => (
                                            <TableRow key={seat.id}>
                                              <TableCell>
                                                {seat.number}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </CollapsibleContent>
                                </Collapsible>
                              ))}
                          </CollapsibleContent>
                        </Collapsible>
                      ))}
                  </CollapsibleContent>
                </Collapsible>
              ))}
          </CollapsibleContent>
        </Collapsible>
      ))}
    </div>
  );
}

function VenueTemplateForm({
  onSubmit,
}: {
  onSubmit: (data: Omit<Template, "id">) => void;
}) {
  const [formData, setFormData] = useState({ name: "", description: "" });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="name">Template Name</Label>
        <Input
          id="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
      </div>
      <div>
        <Label htmlFor="description">Description</Label>
        <Input
          id="description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
      </div>
      <Button type="submit">Create Venue Template</Button>
    </form>
  );
}

function SectionGroupForm({
  onSubmit,
}: {
  onSubmit: (data: Omit<SectionGroup, "id" | "templateId">) => void;
}) {
  const [formData, setFormData] = useState({ name: "", description: "" });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="name">Section Group Name</Label>
        <Input
          id="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
      </div>
      <div>
        <Label htmlFor="description">Description</Label>
        <Input
          id="description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
      </div>
      <Button type="submit">Create Section Group</Button>
    </form>
  );
}

function SectionForm({
  onSubmit,
}: {
  onSubmit: (data: Omit<Section, "id" | "sectionGroupId">) => void;
}) {
  const [formData, setFormData] = useState({ name: "", description: "" });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="name">Section Name</Label>
        <Input
          id="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
      </div>
      <div>
        <Label htmlFor="description">Description</Label>
        <Input
          id="description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
      </div>
      <Button type="submit">Create Section</Button>
    </form>
  );
}

function RowForm({
  onSubmit,
}: {
  onSubmit: (data: Omit<Row, "id" | "sectionId">) => void;
}) {
  const [formData, setFormData] = useState({ name: "" });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="name">Row Name</Label>
        <Input
          id="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
      </div>
      <Button type="submit">Create Row</Button>
    </form>
  );
}

function SeatForm({
  onSubmit,
}: {
  onSubmit: (data: { startNumber: number; endNumber: number }) => void;
}) {
  const [formData, setFormData] = useState({ startNumber: 1, endNumber: 1 });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="startNumber">Start Number</Label>
        <Input
          id="startNumber"
          type="number"
          value={formData.startNumber}
          onChange={(e) =>
            setFormData({ ...formData, startNumber: parseInt(e.target.value) })
          }
          required
        />
      </div>
      <div>
        <Label htmlFor="endNumber">End Number</Label>
        <Input
          id="endNumber"
          type="number"
          value={formData.endNumber}
          onChange={(e) =>
            setFormData({ ...formData, endNumber: parseInt(e.target.value) })
          }
          required
        />
      </div>
      <Button type="submit">Create Seats</Button>
    </form>
  );
}
