import React from "react";
import { Button } from "../../../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../../components/ui/table";
import {
  AvailabilityType,
  DealVariantAvailability,
} from "../../../../../../models/deal.model";

interface AvailabilityListProps {
  availabilities: DealVariantAvailability[];
  onEdit: (availability: DealVariantAvailability) => void;
  onDelete: (availability: DealVariantAvailability) => void;
}

export const AvailabilityList: React.FC<AvailabilityListProps> = ({
  availabilities,
  onEdit,
  onDelete,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Availabilities</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Type</TableHead>
              <TableHead>Date/Range</TableHead>
              <TableHead>Time</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Quantity</TableHead>
              <TableHead>Remaining</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {availabilities.map((availability) => (
              <TableRow key={availability.id}>
                <TableCell>{availability.availabilityType}</TableCell>
                <TableCell>
                  {availability.availabilityType ===
                    AvailabilityType.SPECIFIC && availability.date}
                  {availability.availabilityType === AvailabilityType.RANGE &&
                    `${availability.startDate} - ${availability.endDate}`}
                </TableCell>
                <TableCell>{availability.time || "All day"}</TableCell>
                <TableCell>{availability.price}</TableCell>
                <TableCell>{availability.quantity}</TableCell>
                <TableCell>{availability.remaining}</TableCell>
                <TableCell>
                  <Button
                    variant="outline"
                    size="sm"
                    className="mr-2"
                    onClick={() => onEdit(availability)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => onDelete(availability)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
