import React, { useEffect, useState } from "react";
import { Button } from "../../../../../components/common";
import {
  AnimatedLoading,
  AnimatedSuccess,
} from "../../../../../components/common/Animation/Animated";
import { Modal } from "../../../../../components/common/Modals";
import { RefundOrderDto, RefundType } from "../../../../../models/order.model";
import {
  CustomError,
  useProcessOrderRefundMutation,
} from "../../../../../services/order.api";
import "./ConfirmModal.css";

interface ComponentProps {
  open: boolean; // Determines if the modal is open or not
  setOpen: React.Dispatch<React.SetStateAction<boolean>>; // Function to set the modal open/close state
  title: string; // Title of the modal
  size: "xs" | "s" | "m" | "l"; // Modal size options
  orderId: string; // The ID of the order to be refunded
  setOrderId: React.Dispatch<React.SetStateAction<string>>; // Function to reset the order ID if needed
}

export const OrderRefundModal: React.FC<ComponentProps> = ({
  open,
  setOpen,
  title,
  size,
  orderId,
  setOrderId,
}) => {
  const [refundReason, setRefundReason] = useState("");
  const [refundType, setRefundType] = useState<RefundType>(RefundType.SYSTEM);

  // Additional fields for manual refunds
  const [bankName, setBankName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [refundAmount, setRefundAmount] = useState("");

  const [processRefund, { isLoading, isSuccess, isError, error }] =
    useProcessOrderRefundMutation();
  console.log("orderId", orderId);
  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setOpen(false);
        setOrderId && setOrderId("");
        setRefundReason("");
        setRefundType(RefundType.SYSTEM);
        setBankName("");
        setBankAccount("");
        setBankAccountName("");
        setRefundAmount("");
      }, 1500); // Close modal after 1.5 seconds on success
      return () => clearTimeout(timer);
    }

    // Cleanup item on unmount or if modal is closed manually
    return () => {
      setOrderId && setOrderId("");
    };
  }, [isSuccess, setOpen, setOrderId]);

  // Handle refund submission
  const handleRefund = async () => {
    const refundData: RefundOrderDto = {
      reason: refundReason,
      type: refundType,
      details:
        refundType === RefundType.MANUAL
          ? {
              bankName,
              bankAccount,
              bankAccountName,
              refundAmount,
            }
          : undefined,
    };
    try {
      await processRefund({
        id: orderId,
        body: {
          refundedThrough: JSON.stringify(refundData),
        },
      }).unwrap();
    } catch (err) {
      console.error("Error processing refund:", err);
    }
  };

  // Conditionally render the error message based on the type of error received
  const renderErrorMessage = () => {
    if (isError && error) {
      return (
        <div className="text-red-500">
          <h3 className="font-semibold">Error(s)</h3>
          <ul>
            <li>{(error as CustomError).data.message}</li>
          </ul>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal open={open} setOpen={setOpen} title={title} size={size}>
      <div className="grid justify-center gap-6 p-8">
        {/* Loading State */}
        {isLoading && !isSuccess && !isError && <AnimatedLoading />}

        {/* Success State */}
        {isSuccess && !isLoading && <AnimatedSuccess />}

        {/* Error State */}
        {renderErrorMessage()}

        {/* Default Confirmation State */}
        {!isSuccess && !isLoading && !isError && (
          <>
            {/* Refund Reason Field */}
            <div className="mb-4">
              <label
                htmlFor="refundReason"
                className="block text-sm font-medium text-gray-700"
              >
                Refund Reason
              </label>
              <textarea
                id="refundReason"
                className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                value={refundReason}
                onChange={(e) => setRefundReason(e.target.value)}
              />
            </div>

            {/* Refund Type Field */}
            <div className="mb-4">
              <label
                htmlFor="refundType"
                className="block text-sm font-medium text-gray-700"
              >
                Refund Type
              </label>
              <select
                id="refundType"
                className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                value={refundType}
                onChange={(e) => setRefundType(e.target.value as RefundType)}
              >
                <option value={RefundType.SYSTEM}>System</option>
                <option value={RefundType.MANUAL}>Manual</option>
              </select>
            </div>

            {/* Additional Fields for Manual Refund */}
            {refundType === RefundType.MANUAL && (
              <>
                <div className="mb-4">
                  <label
                    htmlFor="bankName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Bank Name
                  </label>
                  <input
                    type="text"
                    id="bankName"
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="bankAccount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Bank Account
                  </label>
                  <input
                    type="text"
                    id="bankAccount"
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                    value={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="bankAccountName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Bank Account Name
                  </label>
                  <input
                    type="text"
                    id="bankAccountName"
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                    value={bankAccountName}
                    onChange={(e) => setBankAccountName(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="refundAmount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Refund Amount
                  </label>
                  <input
                    type="number"
                    id="refundAmount"
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md"
                    value={refundAmount}
                    onChange={(e) => setRefundAmount(e.target.value)}
                  />
                </div>
              </>
            )}

            {/* Action Buttons */}
            <div className="flex justify-center gap-5">
              <Button
                title="Proceed"
                variant="secondary"
                onClick={handleRefund}
              />
              <Button
                title="Cancel"
                variant="warning"
                onClick={() => {
                  setOpen(false); // Close the modal
                  setOrderId && setOrderId(""); // Reset the order ID if applicable
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
