import { Edit } from "lucide-react";
import React, { useState } from "react";
import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { useToast } from "../../../../../hooks/use-toast";
import { useUpdateDealVariantMutation } from "../../../../../services/deal.api";
import DealVariantForm, { UpdateVariantFormValues } from "../DealVariantForm";

interface EditVariantDialogProps {
  id: string;
  variant: UpdateVariantFormValues;
}

export const EditDealVariantFormDialog: React.FC<EditVariantDialogProps> = ({
  id,
  variant,
}) => {
  const { toast } = useToast();
  const [updateDealVariant] = useUpdateDealVariantMutation();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdateVariant = async (
    updatedVariant: UpdateVariantFormValues
  ) => {
    try {
      await updateDealVariant({ ...updatedVariant, dealId: id }).unwrap();
      setIsOpen(false);

      toast({
        title: "Deal Variant Updated",
        description: "The deal variant has been successfully updated.",
        variant: "success",
      });
    } catch (error) {
      console.error("Error updating deal variant:", error);
      toast({
        title: "Error",
        description:
          "There was an error updating the deal variant. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="flex items-center gap-2">
          <Edit className="w-4 h-4" />
          Edit Variant
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Edit Variant</DialogTitle>
        <DealVariantForm
          variant={variant}
          onSubmit={(value) =>
            handleUpdateVariant(value as UpdateVariantFormValues)
          }
        />
        <DialogClose asChild>
          <Button variant="secondary" className="mt-4">
            Cancel
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default EditDealVariantFormDialog;
