import React from "react";
import Placeholder from "../../../assets/images/placeholder.jpg";
import { PaginationContainer } from "../../../components/common";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { Modal } from "../../../components/common/Modals/Modal";
import { Button } from "../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select"; // Ensure Select component is imported
import { Category, CategoryType } from "../../../models/category.model";
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from "../../../services/category.api";
import { UpdateCategory } from "../UpdateCategory";

interface ComponentProps {
  search?: string;
}

export const CategoryList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);
  const [category, setCategory] = React.useState<Category>();
  const [typeFilter, setTypeFilter] = React.useState<CategoryType | "all">(
    "all"
  ); // Filter for category type
  const [parentStatusFilter, setParentStatusFilter] = React.useState<
    "all" | "no-parent" | "with-parent"
  >("all"); // Filter for parent status

  const { data, isLoading } = useGetCategoriesQuery({
    skip,
    take,
    search: search || "",
    type: typeFilter !== "all" ? typeFilter : undefined, // Pass type filter if not "all"
    hasParent:
      parentStatusFilter === "all"
        ? undefined
        : parentStatusFilter === "with-parent",
  });

  const [deleteCategory] = useDeleteCategoryMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div className="space-y-4">
      <div className="flex justify-between p-2">
        <h2 className="text-lg font-semibold">Categories</h2>
        <div className="flex items-center space-x-4">
          <Select
            onValueChange={(value) =>
              setTypeFilter(value as CategoryType | "all")
            }
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Filter by Type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Types</SelectItem>
              <SelectItem value={CategoryType.EVENT}>Events</SelectItem>
              <SelectItem value={CategoryType.PRODUCT}>Products</SelectItem>
              <SelectItem value={CategoryType.DEAL}>Deals</SelectItem>
            </SelectContent>
          </Select>
          <Select
            onValueChange={(value) =>
              setParentStatusFilter(
                value as "all" | "no-parent" | "with-parent"
              )
            }
          >
            <SelectTrigger className="w-48">
              <SelectValue placeholder="Filter by Parent Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Categories</SelectItem>
              <SelectItem value="no-parent">No Parent Only</SelectItem>
              <SelectItem value="with-parent">With Parent Only</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div>
        <ul className="grid grid-cols-5 p-2 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Slug</li>
          <li>Type</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((category: Category, index: number) => (
              <ul
                className="grid items-center grid-cols-5 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center">
                    <li className="mr-4">
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(category.id);
                        }}
                      >
                        Copy ID
                      </Button>
                    </li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hiddenrounded w-14 h-14">
                        <img
                          alt={`${category.name}_image`}
                          src={
                            category.image
                              ? process.env.REACT_APP_IMAGE_BASE_URL +
                                category.image?.fileName
                              : Placeholder
                          }
                        />
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{category.name}</li>
                <li>{category.slug}</li>
                <li>{category.type}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setCategory(category);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setCategory(category);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {category && (
        <Modal open={open} setOpen={setOpen} title="Update Category" size="m">
          <UpdateCategory setOpen={setOpen} categoryId={category.id} />
        </Modal>
      )}
      {category && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Category`}
          size="s"
          action={deleteCategory}
          item={category.id}
        />
      )}
    </div>
  );
};
