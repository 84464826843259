import { Category } from "./category.model";
import { Image } from "./image.model";

export interface Deal {
  id: string;
  organizationId: string;
  name: string;
  description?: string;
  category: Category;
  categoryId: string;
  startDate: string;
  endDate: string;
  isFeatured: boolean;
  isPublished: boolean;
  dealDetails?: DealDetail[];
  dealVariants?: DealVariant[];
  dealImages?: DealImage[];
}

export interface CreateDeal {
  organizationId: string;
  name: string;
  description?: string;
  categoryId: string;
  startDate: Date;
  endDate: Date;
  isFeatured: boolean;
  isPublished: boolean;
  dealDetails?: CreateDealDetail[];
  dealVariants?: CreateDealVariant[];
  dealImages?: CreateDealImage[]; // Support for uploading images during creation
}

export interface UpdateDeal {
  id: string;
  name: string;
  description?: string;
  categoryId: string;
  startDate: string;
  endDate: string;
  isFeatured: boolean;
  isPublished: boolean;
  dealDetails?: UpdateDealDetail[];
  dealVariants?: UpdateDealVariant[];
  dealImages?: UpdateDealImage[];
}

export interface DealDetail {
  id: string;
  title: string;
  description?: string;
  inclusions?: string[];
  exclusions?: string[];
}

export interface CreateDealDetail {
  dealId: string;
  title: string;
  description?: string;
  inclusions?: string[];
  exclusions?: string[];
}

export interface UpdateDealDetail extends CreateDealDetail {
  id: string;
}

export interface DealVariant {
  id: string;
  dealId: string;
  name: string;
  isPublished: boolean;
  dealVariantImages?: DealVariantImage[];
  availabilities?: DealVariantAvailability[]; // Nested availabilities
  availabilityType: AvailabilityType;
}

export interface CreateDealVariant {
  name: string;
  availabilityType: AvailabilityType;
  isPublished: boolean;
  dealId: string;
}

export interface UpdateDealVariant extends CreateDealVariant {
  id: string;
}

export interface DealImage {
  id: string;
  isPrimary: boolean;
  image: Image;
}

export interface CreateDealImage {
  isPrimary: boolean;
  image: Image; // Assumes that `Image` includes fields for new image creation, like `url`, `altText`, etc.
}

export interface UpdateDealImage extends CreateDealImage {
  id: string;
}

export interface DealVariantImage {
  id: string;
  isPrimary: boolean;
  image: Image;
}

export interface DealVariantAvailability {
  id: string;
  availabilityType: AvailabilityType;
  date?: string; // Now a string
  time?: string;
  startDate?: string; // Now a string
  endDate?: string; // Now a string
  price: number;
  quantity: number;
  remaining: number;
}

export interface CreateDealVariantAvailability {
  availabilityType: AvailabilityType;
  date?: string; // Changed to string for consistency
  time?: string;
  startDate?: string;
  endDate?: string;
  price: number;
  quantity: number;
}

export interface UpdateDealVariantAvailability
  extends CreateDealVariantAvailability {
  id: string;
  remaining?: number;
}

// Enum for availability type
export enum AvailabilityType {
  SPECIFIC = "SPECIFIC",
  RANGE = "RANGE",
}
