import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import {
  Category,
  CategoryType,
  CreateCategory,
  UpdateCategory,
} from "../models/category.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Categories {
  data: Category[];
  skip: number;
  take: number;
  count: number;
}

interface CategoriesQuery {
  skip?: number;
  take?: number;
  search?: string;
  type?: CategoryType;
  hasParent?: boolean;
}

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/categories",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Categories", "Category"],
  endpoints: (builder) => ({
    createCategory: builder.mutation<Category, CreateCategory>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
    getCategories: builder.query<Categories, CategoriesQuery>({
      query: ({ take, skip, search, type, hasParent }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
            type,
            hasParent,
          },
        };
      },
      providesTags: ["Categories"],
    }),
    getCategory: builder.query<Category, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Category"],
    }),
    updateCategory: builder.mutation<Category, UpdateCategory>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
    uploadCategoryImage: builder.mutation<Category, { id: string; file: File }>(
      {
        query: ({ id, ...body }) => {
          const formData = new FormData();
          formData.append("file", body.file);
          return {
            url: `/${id}/upload`,
            method: "PATCH",
            body: formData,
          };
        },
        invalidatesTags: ["Categories", "Category"],
      }
    ),
    deleteCategory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Categories"],
    }),
    removeCategoryPhoto: builder.mutation<
      void,
      { categoryId: string; imageId?: string }
    >({
      query: ({ categoryId, imageId }) => ({
        url: `/${categoryId}/image/${imageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Categories", "Category"],
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useGetCategoryQuery,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useUploadCategoryImageMutation,
  useDeleteCategoryMutation,
  useRemoveCategoryPhotoMutation,
  useLazyGetCategoriesQuery,
} = categoryApi;
