import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import ImageUploadForm from "../../../components/common/ImageUploadForm";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { useToast } from "../../../hooks/use-toast";
import {
  CustomError,
  useGetCategoryQuery,
  useRemoveCategoryPhotoMutation,
  useUpdateCategoryMutation,
  useUploadCategoryImageMutation,
} from "../../../services/category.api";

interface FormValues {
  name: string;
}

interface ComponentProps {
  categoryId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = z.object({
  name: z.string().min(1, "Name is required."),
});

export const UpdateCategory: React.FC<ComponentProps> = ({
  setOpen,
  categoryId,
}) => {
  const { toast } = useToast();
  const [open, setOpenDialog] = useState(false);
  const { data: category, isLoading: categoryIsLoading } =
    useGetCategoryQuery(categoryId);
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateCategoryMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadCategoryImageMutation();
  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveCategoryPhotoMutation();
  const ref = useRef<HTMLInputElement | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: category?.name || "",
    },
  });

  useEffect(() => {
    if (category) {
      setValue("name", category.name);
    }
  }, [category, setValue]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
      toast({
        title: "Success",
        description: "Category updated successfully",
        variant: "success",
      });
    }
  }, [isSuccess, isLoading, setOpen]);

  const onSubmit = async (values: FormValues) => {
    try {
      // await update({ ...category, ...values });
      toast({
        title: "Success",
        description: "Category updated successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update category",
        variant: "destructive",
      });
    }
  };

  const uploadImage = async (file: File) => {
    try {
      await upload({ id: categoryId, file });
      setOpenDialog(false);
      toast({
        title: "Success",
        description: "Image uploaded successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to upload image",
        variant: "destructive",
      });
    }
  };

  const handleRemovePhoto = async () => {
    if (!category?.image?.id) return;
    try {
      await removePhoto({
        categoryId: category?.id,
        imageId: category?.image?.id || "",
      });
      toast({
        title: "Success",
        description: "Photo removed successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to remove photo",
        variant: "destructive",
      });
    }
  };

  if (isLoading || categoryIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;

  if (!category) return <h2>Something went wrong...</h2>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-2">
        {isError && error && (
          <div className="text-red-500">
            <h3 className="font-semibold">Error(s)</h3>
            <ul>
              {(error as CustomError).data.message.map((message) => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="flex items-center">
          <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
            {category && category.image ? (
              <img
                alt={`${category.name}_image`}
                src={
                  process.env.REACT_APP_IMAGE_BASE_URL +
                  category.image?.fileName
                }
              />
            ) : (
              <div className="bg-black " />
            )}
          </div>
          <div className="grid grid-cols-3 gap-1">
            <Dialog open={open} onOpenChange={setOpenDialog}>
              <DialogTrigger asChild>
                <Button>Upload</Button>
              </DialogTrigger>
              <DialogContent>
                <ImageUploadForm onSubmit={uploadImage} />
              </DialogContent>
            </Dialog>
            {category.image ? (
              <Button variant={"destructive"} onClick={handleRemovePhoto}>
                Remove
              </Button>
            ) : (
              <div></div>
            )}
            <p className="col-span-2 text-xs">
              Image should be at least 400 x 400px as a png or jpeg file.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <Label htmlFor="name">Name</Label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input placeholder="Name" id="name" {...field} />
            )}
          />
          {errors.name && (
            <div className="text-red-500">{errors.name.message}</div>
          )}
        </div>
        <Button
          type="submit"
          className="px-4 py-2 font-bold text-white bg-blue-900 rounded"
        >
          {isLoading ? "Updating..." : "Update"}
        </Button>
      </div>
    </form>
  );
};
