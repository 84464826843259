import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { DealVariantAvailability } from "../models/deal.model";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getLowestPriceDealVariantAvailability(
  dealVariantAvailabilities: DealVariantAvailability[]
) {
  if (!dealVariantAvailabilities || dealVariantAvailabilities.length === 0) {
    return null; // Return null or throw an error if preferred
  }

  // Initialize with a high value to ensure that any valid price will be lower
  let lowestPriceAvailability = null;

  for (const availability of dealVariantAvailabilities) {
    // Ensure the availability has quantity before considering its price
    if (availability.quantity > 0) {
      if (
        !lowestPriceAvailability ||
        availability.price < lowestPriceAvailability.price
      ) {
        lowestPriceAvailability = availability;
      }
    }
  }

  return lowestPriceAvailability;
}
