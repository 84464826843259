import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { useRef } from "react";
import { Input, Label } from "../../../components/common";

import * as Yup from "yup";
import {
  CustomError,
  useGetVenueQuery,
  useRemoveVenuePhotoMutation,
  useUpdateVenueMutation,
  useUploadVenueImageMutation,
} from "../../../services/venue.api";

interface FormValues {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  country: string;
  lat?: number;
  lng?: number;
}

interface ComponentProps {
  venueId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateVenue: React.FC<ComponentProps> = ({ setOpen, venueId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateVenueMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadVenueImageMutation();

  const { data: venue, isLoading: venueIsLoading } = useGetVenueQuery(venueId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveVenuePhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (venue)
      update({
        id: venue.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: venueId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(venueId);
  };

  if (isLoading || venueIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!venue) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: venue?.name || "",
    address1: venue?.address1 || "",
    address2: venue?.address2 || "",
    city: venue?.city || "",
    state: venue?.state || "",
    country: venue?.country || "",
    lat: venue?.lat || 0,
    lng: venue?.lng || 0,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    address1: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    lat: Yup.number().required(),
    lng: Yup.number().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {venue && venue.image ? (
                    <img
                      alt={`${venue.name}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        venue.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="address1">Address 1</Label>
                <Field name="address1">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Address 1" id="address1" {...field} />
                  )}
                </Field>
                <Label htmlFor="address2">Address 2</Label>
                <Field name="address2">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Address 2 (Optional)"
                      id="address2"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="city">City</Label>
                <Field name="city">
                  {({ field }: FieldProps) => (
                    <Input placeholder="City" id="city" {...field} />
                  )}
                </Field>
                <Label htmlFor="state">State</Label>
                <Field name="state">
                  {({ field }: FieldProps) => (
                    <Input placeholder="State" id="state" {...field} />
                  )}
                </Field>
                <Label htmlFor="country">Country</Label>
                <Field name="country">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Country" id="country" {...field} />
                  )}
                </Field>
                <Label htmlFor="lat">Latitude</Label>
                <Field name="lat">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Latitude"
                      type="number"
                      id="lat"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="lng">Longitude</Label>
                <Field name="lng">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Longitude"
                      type="number"
                      id="lng"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateVenue;
