import ConversationList from "../../features/Conversation/ConversationList";

export const ConversationPage = () => {
  return (
    <div>
      <ConversationList />
    </div>
  );
};

export default ConversationPage;
