import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Switch } from "../../../components/ui/switch";
import { Textarea } from "../../../components/ui/textarea";
import CategorySelectV2 from "../../../features/Category/CategorySelect/CategorySelectV2";
import { CategoryType } from "../../../models/category.model";
import {
  CustomError,
  useCreateCategoryMutation,
} from "../../../services/category.api";

const validationSchema = z.object({
  name: z.string().min(1, "Name is required."),
  description: z.string().optional(),
  type: z.string().min(1, "Type is required."),
  isActive: z.boolean().optional().default(true),
  parentId: z.string().optional(),
});

type FormValues = z.infer<typeof validationSchema>;

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCategory: React.FC<ComponentProps> = ({ setOpen }) => {
  const [createCategory, { isLoading, isError, error, isSuccess }] =
    useCreateCategoryMutation();

  const form = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      type: CategoryType.EVENT, // Default to EVENT category type
      isActive: true,
    },
  });

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const onSubmit = async (values: FormValues) => {
    await createCategory({
      ...values,
      type: values.type as CategoryType,
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {isError && error && (
          <div className="text-red-500">
            <h3 className="font-semibold">Error(s)</h3>
            <ul>
              {(error as CustomError).data.message.map((message) => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          </div>
        )}

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Category Type</FormLabel>
              <FormControl>
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="px-2 py-2 border rounded"
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Select Category Type
                      </option>
                      <option value={CategoryType.EVENT}>Event</option>
                      <option value={CategoryType.PRODUCT}>Product</option>
                      <option value={CategoryType.DEAL}>Deal</option>
                    </select>
                  )}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="parentId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Parent Category</FormLabel>
              <FormControl>
                <CategorySelectV2
                  name="parentId"
                  type={form.watch("type") as CategoryType}
                  hasParent
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="isActive"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between p-4 border rounded-lg">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Active</FormLabel>
                <FormDescription>Make this category active</FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button type="submit" disabled={isLoading}>
          {isLoading ? "Adding..." : "Add Category"}
        </Button>
      </form>
    </Form>
  );
};
