import { useRef, useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

interface ImageUploadFormProps {
  onSubmit: (image: File) => void;
}

const ImageUploadForm = ({ onSubmit }: ImageUploadFormProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    if (selectedFile) {
      // Validate file type
      if (!selectedFile.type.startsWith("image/")) {
        setError("Please select a valid image file.");
        return;
      }

      // Validate file size (example: 5MB max)
      const maxSizeMB = 5;
      if (selectedFile.size > maxSizeMB * 1024 * 1024) {
        setError(`File size should not exceed ${maxSizeMB}MB.`);
        return;
      }

      // Set the file and its preview URL
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setError(null);
    }
  };

  const handleBrowse = () => {
    fileInputRef.current?.click();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (file) {
      // You would typically upload the file to your server or cloud storage here
      // For this example, we're using a fake URL for demonstration purposes
      onSubmit(file);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="image">Image</Label>
        <div className="flex items-center space-x-2">
          <Button type="button" onClick={handleBrowse}>
            Browse
          </Button>
          <Input
            ref={fileInputRef}
            id="image"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
      </div>

      {previewUrl && (
        <div className="mt-4">
          <Label>Preview</Label>
          <img
            src={previewUrl}
            alt="Selected preview"
            className="object-cover w-32 h-32 rounded-md"
          />
        </div>
      )}

      <Button type="submit" disabled={!file}>
        Upload Image
      </Button>
    </form>
  );
};

export default ImageUploadForm;
