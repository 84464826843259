import Logger, { LogLevel } from "./logger";

const logger = new Logger({
  level: (process.env.REACT_APP_LOG_LEVEL as LogLevel) || "debug",
  useCloudWatch: process.env.REACT_APP_USE_CLOUDWATCH === "true",
  logGroupName: process.env.REACT_APP_LOG_GROUP_NAME,
  logStreamName: process.env.REACT_APP_LOG_STREAM_NAME,
});

export default logger;
