import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { DealVariant } from "../../../../models/deal.model";
import {
  useCreateDealVariantMutation,
  useDeleteDealVariantMutation,
  useGetDealVariantsQuery,
} from "../../../../services/deal.api";

import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../hooks/use-toast";
import DealVariantForm, { NewVariantFormValues } from "./DealVariantForm";

// Zod schema for form validation

type VariantsPageParams = {
  id: string;
};

export function VariantsPage() {
  const { id } = useParams<VariantsPageParams>() as VariantsPageParams;
  const navigate = useNavigate();
  const { toast } = useToast();
  const { data: variants } = useGetDealVariantsQuery({ dealId: id });
  const [createDealVariant] = useCreateDealVariantMutation();
  const [deleteDealVariant] = useDeleteDealVariantMutation();

  const [isAddVariantOpen, setIsAddVariantOpen] = useState(false);

  const [deletingVariant, setDeletingVariant] = useState<DealVariant | null>(
    null
  );

  const handleAddVariant = async (newVariant: NewVariantFormValues) => {
    try {
      await createDealVariant({ ...newVariant, dealId: id }).unwrap();
      setIsAddVariantOpen(false);

      toast({
        title: "Deal Variant Created",
        description: "The deal variant has been successfully created.",
        variant: "success",
      });
    } catch (error) {
      console.error("Error creating deal variant:", error);
      toast({
        title: "Error",
        description:
          "There was an error creating the deal variant. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDeleteVariant = async () => {
    if (deletingVariant) {
      try {
        await deleteDealVariant({
          dealId: id,
          id: deletingVariant.id,
        }).unwrap();
        setDeletingVariant(null);

        toast({
          title: "Deal Variant Deleted",
          description: "The deal variant has been successfully deleted.",
          variant: "success",
        });
      } catch (error) {
        console.error("Error deleting deal variant:", error);
        toast({
          title: "Error",
          description:
            "There was an error deleting the deal variant. Please try again.",
          variant: "destructive",
        });
      }
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4 ">
        <h2 className="text-xl font-semibold">Variants</h2>
        <Dialog open={isAddVariantOpen} onOpenChange={setIsAddVariantOpen}>
          <DialogTrigger asChild>
            <Button>Add Variant</Button>
          </DialogTrigger>
          <DialogContent className="bg-white">
            <DialogHeader>
              <DialogTitle>Add New Variant</DialogTitle>
              <DialogDescription>
                Enter the details for the new variant.
              </DialogDescription>
            </DialogHeader>
            <DealVariantForm onSubmit={handleAddVariant} />
          </DialogContent>
        </Dialog>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Availability Type</TableHead>
            <TableHead>Published</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {variants?.data.map((variant) => (
            <TableRow key={variant.id}>
              <TableCell>{variant.name}</TableCell>
              <TableCell>{variant.availabilityType}</TableCell>
              <TableCell>{variant.isPublished ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Button
                  variant="outline"
                  className="mr-2"
                  onClick={() =>
                    navigate(`/deals/${id}/variants/${variant.id}`)
                  }
                >
                  View
                </Button>

                <Button
                  variant="destructive"
                  onClick={() => setDeletingVariant(variant)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {deletingVariant && (
        <Dialog
          open={!!deletingVariant}
          onOpenChange={() => setDeletingVariant(null)}
        >
          <DialogContent className="bg-white">
            <DialogHeader>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete the variant "
                {deletingVariant.name}"? This action cannot be undone.
              </DialogDescription>
            </DialogHeader>
            <div className="flex justify-end mt-4">
              <Button
                variant="outline"
                className="mr-2"
                onClick={() => setDeletingVariant(null)}
              >
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDeleteVariant}>
                Confirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default VariantsPage;
