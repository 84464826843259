import { useParams } from "react-router-dom";
import { Badge } from "../../../../../components/ui/badge";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import { AvailabilityType } from "../../../../../models/deal.model";
import { useGetDealVariantQuery } from "../../../../../services/deal.api";
import { DealVariantAvailabilityComponent } from "./DealVariantAvailability";
import EditDealVariantFormDialog from "./EditDealVariantFormDialog";

type SelectedDealVariantPageParams = {
  id: string;
  variantId: string;
};
const SelectedDealVariantPage = () => {
  const { id, variantId } =
    useParams<SelectedDealVariantPageParams>() as SelectedDealVariantPageParams;

  const { data: variant, isLoading } = useGetDealVariantQuery({
    dealId: id,
    variantId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!variant) {
    return <div>Variant not found</div>;
  }
  return (
    <div className="space-y-6">
      <div className="grid gap-6 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle>{variant.name}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div>
              <p className="text-sm font-medium">Status</p>
              <Badge variant={variant.isPublished ? "default" : "secondary"}>
                {variant.isPublished ? "Published" : "Draft"}
              </Badge>
            </div>

            <div>
              <p className="text-sm font-medium">Availability Type</p>
              <Badge
                variant={
                  variant.availabilityType === AvailabilityType.SPECIFIC
                    ? "default"
                    : "secondary"
                }
              >
                {variant.availabilityType}
              </Badge>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Actions</CardTitle>
          </CardHeader>
          <CardContent>
            <EditDealVariantFormDialog id={id} variant={variant} />
          </CardContent>
        </Card>
      </div>
      <DealVariantAvailabilityComponent
        id={id}
        dealVariantId={variantId}
        availabilityType={variant.availabilityType}
      />
    </div>
  );
};

export default SelectedDealVariantPage;
