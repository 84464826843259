import React from "react";
import { DealVariantAvailability } from "../../../../../../models/deal.model";
import {
  AvailabilityForm,
  UpdateAvailabilityFormValues,
} from "./AvailabilityForm";

interface UpdateAvailabilityFormProps {
  availability: DealVariantAvailability;
  onSubmit: (data: UpdateAvailabilityFormValues) => void;
  onCancel: () => void;
}

export const UpdateAvailabilityForm: React.FC<UpdateAvailabilityFormProps> = ({
  availability,
  onSubmit,
  onCancel,
}) => {
  return (
    <AvailabilityForm
      onSubmit={(data) => onSubmit({ ...data, id: availability.id })} // Ensure id is included for update
      onCancel={onCancel}
      defaultValues={{
        ...availability,
        date: availability.date || "",
        startDate: availability.startDate || "",
        endDate: availability.endDate || "",
      }}
    />
  );
};

export default UpdateAvailabilityForm;
