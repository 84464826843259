import React from "react";
import { PaginationContainer } from "../../../components/common";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { Modal } from "../../../components/common/Modals/Modal";
import { Resell } from "../../../models/resell.model";
import { HiFilter } from "react-icons/hi";
import {
  useDeleteResellMutation,
  useGetResellsQuery,
} from "../../../services/resell.api";
import UpdateResell from "../UpdateResell";
import dayjs from "dayjs";

interface ComponentProps {
  search?: string;
}

interface Filter {
  [key: string]: any;
}

const ResellList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5); // Number of items per page
  const [resell, setResell] = React.useState<Resell | undefined>();
  const [filter, setFilter] = React.useState<Filter>({});
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);

  // Fetch data from resell API
  const { data, isLoading } = useGetResellsQuery({
    skip,
    take,
    search: search || "",
    ...filter,
  });
  console.log("data", data);
  const [deleteResell] = useDeleteResellMutation();

  // Handle page change
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  // Update skip value based on the current page
  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <>
      <div className="flex flex-col p-2 md:flex-row md:justify-between">
        <div className="flex flex-col gap-1 mb-2 lg:flex-row md:mb-0">
          {Object.values(filter).length > 0 &&
            Object.values(filter).map((value: any, index) => {
              return (
                <div
                  className="flex justify-between px-2 py-1 text-white bg-blue-500 rounded-md"
                  key={index}
                >
                  <div>
                    <span className="mr-1 font-semibold capitalize">
                      {Object.keys(filter)[index]}:
                    </span>
                    {`${value}`}
                  </div>
                  <button
                    className="w-6 h-6 ml-2 bg-red-400 rounded-full hover:cursor-pointer"
                    onClick={() => {
                      const tempFilter = filter;
                      delete tempFilter[Object.keys(filter)[index]];
                      setFilter({ ...tempFilter });
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
        </div>
        <button
          onClick={() => setOpenFilter(true)}
          className="flex items-center px-2 py-1 overflow-hidden text-white bg-blue-500 rounded-md"
        >
          <HiFilter /> <span className="ml-2">Filter</span>
        </button>
      </div>
      <div>
        <div className="mb-4">
          <ul className="grid grid-cols-6 p-4 font-semibold bg-gray-100 rounded">
            <li>Created at</li>
            <li>Seller Information</li>
            <li>Event Details</li>
            <li>Seating Info</li>
            <li>Pricing</li>
            <li>Actions</li>
          </ul>
        </div>
        {data && (
          <PaginationContainer
            totalCount={data.count}
            currentPage={page}
            pageSize={take}
            onPageChange={handlePageChange}
          >
            <div className="grid gap-1">
              {data?.data?.map((resell: Resell, index: number) => (
                <div key={index} className="p-4 border rounded shadow-sm">
                  <div className="grid items-center grid-cols-6">
                    {/* ID Column */}
                    <div className="flex flex-col items-start">
                      <span className="font-medium text-gray-700">
                        {dayjs(resell.createdAt).format(
                          "MMMM DD, YYYY - hh:mmA"
                        ) || ""}
                      </span>
                    </div>

                    {/* Seller Information Column */}
                    <div className="flex flex-col">
                      <span className="font-medium">
                        {resell.firstName} {resell.lastName}
                      </span>
                      <span className="text-sm text-gray-600">
                        {resell.email}
                      </span>
                      <span className="text-sm text-gray-600">
                        {resell.contactNumber}
                      </span>
                      <span className="text-sm text-gray-600">
                        {resell.listingType === "SELL" ? (
                          <span className="text-red-400">
                            {resell.listingType}
                          </span>
                        ) : (
                          <span className="text-green-500">
                            {resell.listingType}
                          </span>
                        )}
                      </span>
                    </div>

                    {/* Event Details Column */}
                    <div className="flex flex-col">
                      <span className="font-medium">{resell.eventName}</span>
                      <span className="text-sm text-gray-600">
                        {resell.eventDate}
                      </span>
                      <span className="text-sm text-gray-600">
                        {resell.eventTime}
                      </span>
                    </div>

                    {/* Seating Information Column */}
                    <div className="flex flex-col">
                      <span>Section: {resell.section}</span>
                      <span>Row: {resell.row}</span>
                      <span>
                        Seats: {resell.lowSeat} - {resell.highSeat}
                      </span>
                    </div>

                    {/* Pricing Column */}
                    <div className="flex flex-col">
                      <span className="font-medium">
                        P{resell.pricePerTicket}
                      </span>
                      <span className="text-sm text-gray-600">
                        Quantity: {resell.quantity}
                      </span>
                      <span className="text-sm text-gray-600">
                        Status: {resell.status}
                      </span>
                    </div>

                    {/* Actions Column */}
                    <div className="grid gap-1 lg:grid-cols-2">
                      {/* <button className="px-4 py-2 text-white bg-green-400 rounded">
                      View
                    </button> */}
                      <button
                        className="px-4 py-2 text-white bg-yellow-400 rounded"
                        onClick={() => {
                          setOpen(true);
                          setResell(resell);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="px-4 py-2 text-white bg-red-400 rounded"
                        onClick={() => {
                          setOpenDelete(true);
                          setResell(resell);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </PaginationContainer>
        )}
        {resell && (
          <Modal open={open} setOpen={setOpen} title="Update Resell" size="m">
            <UpdateResell setOpen={setOpen} resellId={resell.id} />
          </Modal>
        )}
        {resell && openDelete && (
          <ConfirmModal
            open={openDelete}
            setOpen={setOpenDelete}
            title={`Delete Resell`}
            size="s"
            action={deleteResell}
            item={resell.id}
          />
        )}
      </div>
      <Modal
        open={openFilter}
        setOpen={setOpenFilter}
        title="Event Order Filter"
      >
        <Filter
          filter={filter}
          onClose={() => setOpen(false)}
          setFilter={setFilter}
        />
      </Modal>
    </>
  );
};

export default ResellList;

interface FilterProps {
  setFilter: React.Dispatch<React.SetStateAction<{}>>;
  onClose: () => void;
  filter: any;
}

const Filter: React.FC<FilterProps> = ({ setFilter, onClose, filter }) => {
  const [listingType, setListingType] = React.useState(
    filter.listingType || ""
  );
  const handleSubmit = () => {
    const filter: any = {};
    if (listingType) filter.listingType = listingType;
    setFilter(filter);
    onClose();
  };
  return (
    <div className="bg-gray-500">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="flex p-2">
              <label className="mr-2 text-white">Listing Type</label>
            </td>
            <td className="w-[99%] p-2">
              <select
                className="w-full px-3 py-2 rounded-md"
                value={listingType}
                onChange={(e) => setListingType(e.target.value)}
              >
                <option value="">All</option>
                <option value="BUY">Buy</option>
                <option value="SELL">Sell</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        onClick={() => handleSubmit()}
        className="w-full col-span-2 px-3 py-2 text-white bg-blue-500 rounded-md"
      >
        Filter
      </button>
    </div>
  );
};
