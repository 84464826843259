import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Switch } from "../../../../components/ui/switch";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

import { zodResolver } from "@hookform/resolvers/zod";
import { InfoIcon } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { AvailabilityType } from "../../../../models/deal.model";

const variantSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, "Name is required"),
  availabilityType: z.nativeEnum(AvailabilityType, {
    required_error: "Availability type is required",
  }),
  isPublished: z.boolean(),
});

// TypeScript types based on Zod schema
type VariantFormValues = z.infer<typeof variantSchema>;
export type NewVariantFormValues = Omit<VariantFormValues, "id">;
export type UpdateVariantFormValues = VariantFormValues & { id: string };
interface VariantFormProps {
  variant?: VariantFormValues & { id: string };
  onSubmit: (variant: NewVariantFormValues | UpdateVariantFormValues) => void;
}

export function DealVariantForm({ variant, onSubmit }: VariantFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<VariantFormValues>({
    resolver: zodResolver(variantSchema),
    defaultValues: {
      id: variant?.id || "",
      name: variant?.name || "",
      availabilityType: variant?.availabilityType || AvailabilityType.SPECIFIC,
      isPublished: variant?.isPublished || false,
    },
  });

  const submitHandler = (data: VariantFormValues) => {
    if (variant) {
      onSubmit({ ...data, id: variant.id });
    } else {
      onSubmit(data as NewVariantFormValues);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)} className="space-y-4">
      <div>
        <Label htmlFor="name">Name</Label>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input id="name" {...field} placeholder="Enter variant name" />
          )}
        />
        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
      </div>

      <div>
        <Label htmlFor="availabilityType">
          Availability Type
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <InfoIcon className="inline-block w-4 h-4 ml-1" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Choose how this availability is applied</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </Label>
        <Controller
          name="availabilityType"
          control={control}
          render={({ field }) => (
            <Select
              onValueChange={(value) =>
                field.onChange(value as AvailabilityType)
              }
              defaultValue={field.value}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select availability type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={AvailabilityType.SPECIFIC}>
                  Specific Date
                </SelectItem>
                <SelectItem value={AvailabilityType.RANGE}>
                  Date Range
                </SelectItem>
              </SelectContent>
            </Select>
          )}
        />
        {errors.availabilityType && (
          <p className="mt-1 text-sm text-red-500">
            {errors.availabilityType.message}
          </p>
        )}
      </div>

      <div className="flex items-center space-x-2">
        <Controller
          name="isPublished"
          control={control}
          render={({ field }) => (
            <Switch
              id="isPublished"
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          )}
        />
        <Label htmlFor="isPublished">Published</Label>
      </div>
      <Button type="submit">
        {variant ? "Update Variant" : "Add Variant"}
      </Button>
    </form>
  );
}

export default DealVariantForm;
