import { useState } from "react";
import Placeholder from "../../../assets/images/placeholder.jpg";
import ImageUploadForm from "../../../components/common/ImageUploadForm";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useToast } from "../../../hooks/use-toast";
import {
  useDeleteDealImageMutation,
  useGetDealImagesQuery,
  useSetPrimaryDealImageMutation,
  useUploadDealImageMutation,
} from "../../../services/deal.api";

interface Image {
  id: string;
  url: string;
  isPrimary: boolean;
}

interface ImagesTabProps {
  dealId: string;
}

export function ImagesTab({ dealId }: ImagesTabProps) {
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const { toast } = useToast();
  const { data } = useGetDealImagesQuery({ id: dealId, skip, take });
  const [uploadImage] = useUploadDealImageMutation();
  const [deleteImage] = useDeleteDealImageMutation();
  const [setPrimaryImage] = useSetPrimaryDealImageMutation();
  const [isAddImageOpen, setIsAddImageOpen] = useState(false);

  const handleAddImage = async (file: File) => {
    try {
      await uploadImage({ id: dealId, file });
      toast({
        title: "Success",
        description: "Image uploaded successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to upload image",
        variant: "destructive",
      });
    }
    setIsAddImageOpen(false);
  };

  const handleDeleteImage = async (id: string) => {
    try {
      const response = await deleteImage({ dealId: dealId, imageId: id });

      // Check if the response contains an error
      if ("error" in response) {
        // Extract meaningful error information based on the error object structure
        const errorMessage =
          (response.error as any)?.message ||
          (response.error as any)?.message ||
          "Failed to delete image due to an internal server error";

        // Log the full error object for debugging purposes
        console.error("Delete Image Error:", response.error);

        // Show a toast with the extracted or fallback error message
        toast({
          title: "Error",
          description: errorMessage,
          variant: "destructive",
        });
        return;
      }

      // If the response doesn't contain an error, it's successful
      toast({
        title: "Success",
        description: "Image deleted successfully",
        variant: "success",
      });
    } catch (error) {
      // Log the full error object for debugging purposes
      console.error("Unexpected Error:", error);

      // Show a generic fallback error toast for unexpected exceptions
      toast({
        title: "Error",
        description: "An unexpected error occurred while deleting the image.",
        variant: "destructive",
      });
    }
  };

  const handleSetPrimary = (id: string) => {
    setPrimaryImage({ dealId, imageId: id });
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Images</h2>
        <Dialog open={isAddImageOpen} onOpenChange={setIsAddImageOpen}>
          <DialogTrigger asChild>
            <Button>Add Image</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Image</DialogTitle>
              <DialogDescription>
                Upload a new image for this deal.
              </DialogDescription>
            </DialogHeader>
            <ImageUploadForm onSubmit={handleAddImage} />
          </DialogContent>
        </Dialog>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {data?.data.map((image) => (
          <div key={image.id} className="relative">
            <img
              src={
                process.env.REACT_APP_IMAGE_BASE_URL + image.image.fileName ||
                Placeholder
              }
              alt="Deal"
              className="object-cover w-full h-48 rounded"
            />
            {image.isPrimary && (
              <span className="absolute p-1 font-semibold text-white bg-green-500 rounded-md top-1 left-1">
                Primary
              </span>
            )}
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between p-2 text-white bg-black bg-opacity-50">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleSetPrimary(image.id)}
                disabled={image.isPrimary}
              >
                {image.isPrimary ? "Primary" : "Set as Primary"}
              </Button>
              <Button
                variant="destructive"
                size="sm"
                onClick={() => handleDeleteImage(image.id)}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
