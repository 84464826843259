import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";

import { Button } from "../../../components/ui/button";
import { useToast } from "../../../hooks/use-toast";
import { UpdateDeal } from "../../../models/deal.model";
import {
  useGetDealQuery,
  useUpdateDealMutation,
} from "../../../services/deal.api";
import { DealForm } from "./DealForm";
import { DetailsTab } from "./DetailsTab";
import { ImagesTab } from "./ImagesTab";
type SelectedConversationParams = {
  id: string;
};
export function EditDealPage() {
  const { id } =
    useParams<SelectedConversationParams>() as SelectedConversationParams;
  const navigate = useNavigate();
  const { data: deal, isLoading } = useGetDealQuery(id);
  const [updateDeal] = useUpdateDealMutation();
  const [activeTab, setActiveTab] = useState("main");
  const { toast } = useToast();

  const handleUpdateDeal = async (updatedDeal: UpdateDeal) => {
    try {
      // API call to update deal
      await updateDeal({
        id: updatedDeal.id,
        name: updatedDeal.name,
        description: updatedDeal.description,
        categoryId: updatedDeal.categoryId,
        startDate: updatedDeal.startDate,
        endDate: updatedDeal.endDate,
        isFeatured: updatedDeal.isFeatured,
        isPublished: updatedDeal.isPublished,
      });
      toast({
        title: "Deal Updated",
        description: "The deal has been successfully updated.",
        variant: "success",
      });
    } catch (error) {
      console.error("Error updating deal:", error);
      toast({
        title: "Error",
        description: "There was an error updating the deal. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!deal) {
    return <div>Deal not found</div>;
  }

  return (
    <div className="container p-4 mx-auto">
      <div className="flex justify-between">
        <h1 className="mb-4 text-2xl font-bold">{deal.name}</h1>
        <Button
          variant="secondary"
          className="mb-4"
          onClick={() => navigate(`/deals/${id}/variants`)}
        >
          Manage Variants
        </Button>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="main">Main</TabsTrigger>
          <TabsTrigger value="images">Images</TabsTrigger>
          <TabsTrigger value="details">Details</TabsTrigger>
        </TabsList>
        <TabsContent value="main">
          <DealForm deal={deal} onSubmit={handleUpdateDeal} />
        </TabsContent>
        <TabsContent value="images">
          <ImagesTab dealId={deal.id} />
        </TabsContent>
        <TabsContent value="details">
          <DetailsTab dealId={deal.id} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
