"use client";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";

interface BreadcrumbType {
  label: string;
  path: string;
}

const DealBreadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  if (location.pathname === "/deals") {
    return null;
  }
  // Transform segments into breadcrumb items with labels
  const breadcrumbs: BreadcrumbType[] = pathSegments.map((segment, index) => {
    const path = `/${pathSegments.slice(0, index + 1).join("/")}`;
    let label = segment;

    // Transform common segments into readable labels
    switch (segment) {
      case "deals":
        label = "Deals";
        break;
      case "variants":
        label = "Variants";
        break;
      case "availabilities":
        label = "Availabilities";
        break;
      case "create":
        label = "Create";
        break;
      case "edit":
        label = "Edit";
        break;
      default:
        // Check if it's an ID segment and get previous segment type
        if (/^[0-9a-fA-F-]+$/.test(segment)) {
          const type = pathSegments[index - 1];
          switch (type) {
            case "deals":
              label = "Deal Details";
              break;
            case "variants":
              label = "Variant Details";
              break;
            case "availabilities":
              label = "Availability Details";
              break;
          }
        }
    }

    return { label, path };
  });

  // If we have more than 4 segments, collapse the middle ones
  const showEllipsis = breadcrumbs.length > 4;
  const visibleBreadcrumbs = showEllipsis
    ? [...breadcrumbs.slice(0, 2), ...breadcrumbs.slice(-2)]
    : breadcrumbs;

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {visibleBreadcrumbs.map((item, index) => {
          const isLast = index === visibleBreadcrumbs.length - 1;
          const isEllipsis = showEllipsis && index === 1;

          if (isEllipsis) {
            return (
              <React.Fragment key="ellipsis">
                <BreadcrumbItem>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex items-center gap-1 text-muted-foreground hover:text-foreground">
                      <BreadcrumbEllipsis className="w-4 h-4" />
                      <span className="sr-only">Show hidden breadcrumbs</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                      {breadcrumbs.slice(2, -2).map((hiddenItem) => (
                        <DropdownMenuItem key={hiddenItem.path} asChild>
                          <Link to={hiddenItem.path}>{hiddenItem.label}</Link>
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={item.path}>
              <BreadcrumbItem>
                {isLast ? (
                  <BreadcrumbPage>{item.label}</BreadcrumbPage>
                ) : (
                  <BreadcrumbLink href={item.path} asChild>
                    <Link to={item.path}>{item.label}</Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {!isLast && <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default DealBreadcrumbs;
