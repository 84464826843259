"use client";

import { Eye, Pencil, Trash } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PaginationContainer } from "../../../components/common";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../components/ui/alert-dialog";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Skeleton } from "../../../components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Venue } from "../../../models/venue.model";
import {
  useDeleteVenueMutation,
  useGetVenuesQuery,
} from "../../../services/venue.api";
import UpdateVenue from "../UpdateVenue";

interface ComponentProps {
  search?: string;
}

const VenueList: React.FC<ComponentProps> = ({ search }) => {
  const [page, setPage] = React.useState(1);
  const [take] = React.useState(5);
  const skip = (page - 1) * take;
  const navigate = useNavigate();

  const { data, isLoading } = useGetVenuesQuery({
    skip,
    take,
    search: search || "",
  });

  const [deleteVenue] = useDeleteVenueMutation();

  const [editingVenue, setEditingVenue] = React.useState<Venue | null>(null);
  const [deletingVenue, setDeletingVenue] = React.useState<Venue | null>(null);

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="w-full h-10" />
        <Skeleton className="w-full h-20" />
        <Skeleton className="w-full h-20" />
        <Skeleton className="w-full h-20" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <PaginationContainer
        totalCount={data?.count || 0}
        pageSize={take}
        currentPage={page}
        onPageChange={handlePageChange}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">ID</TableHead>
              <TableHead>Photo</TableHead>
              <TableHead>Name</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.data?.map((venue: Venue) => (
              <TableRow key={venue.id}>
                <TableCell className="font-medium">
                  <Button
                    className="text-white"
                    onClick={() => {
                      navigator.clipboard.writeText(venue.id);
                    }}
                  >
                    Copy ID
                  </Button>
                </TableCell>
                <TableCell>
                  <div className="overflow-hidden rounded-lg w-14 h-14 bg-muted">
                    {venue.image ? (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}${venue.image.fileName}`}
                        alt={`${venue.name}-image`}
                        className="object-cover w-full h-full border-2 border-gray-500 rounded-lg"
                      />
                    ) : (
                      <div className="w-full h-full border-2 border-gray-500 rounded-lg" />
                    )}
                  </div>
                </TableCell>
                <TableCell>{venue.name}</TableCell>
                <TableCell className="flex flex-col gap-2 md:flex-row md:justify-end">
                  <Button
                    onClick={() => {
                      navigate(`/venues/${venue.id}`);
                    }}
                    variant="ghost"
                    size="icon"
                    className="mr-2 "
                  >
                    <Eye className="w-4 h-4" />
                    <span className="sr-only">View</span>
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    className=""
                    onClick={() => setEditingVenue(venue)}
                  >
                    <Pencil className="w-4 h-4" />
                    <span className="sr-only">Edit</span>
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    className=""
                    onClick={() => setDeletingVenue(venue)}
                  >
                    <Trash className="w-4 h-4" />
                    <span className="sr-only">Delete</span>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PaginationContainer>

      <Dialog open={!!editingVenue} onOpenChange={() => setEditingVenue(null)}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Update Venue</DialogTitle>
            <DialogDescription>
              Make changes to the venue here. Click save when you're done.
            </DialogDescription>
          </DialogHeader>
          {editingVenue && (
            <UpdateVenue
              setOpen={() => setEditingVenue(null)}
              venueId={editingVenue.id}
            />
          )}
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={!!deletingVenue}
        onOpenChange={() => setDeletingVenue(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to delete this venue?
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the
              venue and remove its data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (deletingVenue) {
                  deleteVenue(deletingVenue.id);
                  setDeletingVenue(null);
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default VenueList;
