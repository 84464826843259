import React from "react";
import { useParams } from "react-router-dom";
import { Modal } from "../../../components/common/Modals";
import SelectedOrganization from "../../../features/Organization/SelectedOrganization";
import CreateOrganizationApiKey from "../../../features/Organization/SelectedOrganization/OrganizationApiKey/CreateOrganizationApiKey";

type ViewPerformerParams = {
  id: string;
};
export const SelectedOrganizationPage = () => {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams<ViewPerformerParams>() as ViewPerformerParams;
  return (
    <div>
      <button
        className="w-24 px-4 py-2 text-blue-900 border border-blue-900 rounded"
        type="button"
        onClick={() => setOpen(true)}
      >
        Add
      </button>
      <SelectedOrganization organizationId={id} />
      <Modal open={open} setOpen={setOpen} title="Add Organization" size="m">
        <CreateOrganizationApiKey setOpen={setOpen} organizationId={id} />
      </Modal>
    </div>
  );
};

export default SelectedOrganizationPage;
