import React, { useState } from "react";
import { Button } from "../../../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../../../components/ui/dialog";
import {
  AvailabilityType,
  CreateDealVariantAvailability,
  DealVariantAvailability,
} from "../../../../../../models/deal.model";
import {
  useCreateDealVariantAvailabilityMutation,
  useGetDealVariantAvailabilitiesQuery,
  useUpdateDealVariantAvailabilityMutation,
} from "../../../../../../services/deal.api";
import AvailabilityForm, {
  CreateAvailabilityFormValues,
  UpdateAvailabilityFormValues,
} from "./AvailabilityForm"; // Assuming AvailabilityFormTypes is the file with form values types
import { AvailabilityList } from "./AvailabilityList";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateAvailabilityForm from "./UpdateAvailabilityForm";

interface DealVariantAvailabilityComponentProps {
  id: string;
  dealVariantId: string;
  availabilityType: AvailabilityType;
}

export const DealVariantAvailabilityComponent: React.FC<
  DealVariantAvailabilityComponentProps
> = ({ id, dealVariantId, availabilityType }) => {
  const { data: availabilitiesResponse } = useGetDealVariantAvailabilitiesQuery(
    {
      dealId: id,
      variantId: dealVariantId,
    }
  );

  const availabilities = availabilitiesResponse?.data ?? [];

  const [createDealVariantAvailability] =
    useCreateDealVariantAvailabilityMutation();
  const [updateDealVariantAvailability] =
    useUpdateDealVariantAvailabilityMutation();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [editingAvailability, setEditingAvailability] =
    useState<DealVariantAvailability | null>(null);
  const [deletingAvailability, setDeletingAvailability] =
    useState<DealVariantAvailability | null>(null);

  const handleCreateAvailability = async (
    data: CreateAvailabilityFormValues
  ) => {
    const availability: CreateDealVariantAvailability = {
      ...data,
      date: data.date ? new Date(data.date).toISOString() : undefined,
    };

    await createDealVariantAvailability({
      data: availability,
      variantId: dealVariantId,
      dealId: id,
    });
    setIsCreateDialogOpen(false);
  };

  const handleUpdateAvailability = async (
    data: UpdateAvailabilityFormValues
  ) => {
    const availability = {
      ...data,
      id: data.id,
    };

    await updateDealVariantAvailability({
      id: availability.id,
      dealId: id,
      variantId: dealVariantId,
      data: availability,
    });
    setEditingAvailability(null);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Deal Variant Availability</CardTitle>
      </CardHeader>
      <CardContent>
        <Button onClick={() => setIsCreateDialogOpen(true)} className="mb-4">
          Create New Availability
        </Button>

        <AvailabilityList
          availabilities={availabilities}
          onEdit={setEditingAvailability}
          onDelete={setDeletingAvailability}
        />

        {isCreateDialogOpen && (
          <Dialog
            open={isCreateDialogOpen}
            onOpenChange={setIsCreateDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create New Availability</DialogTitle>
              </DialogHeader>

              <AvailabilityForm
                onSubmit={handleCreateAvailability}
                onCancel={() => setIsCreateDialogOpen(false)}
                availabilityType={availabilityType}
              />
            </DialogContent>
          </Dialog>
        )}

        {editingAvailability && (
          <Dialog
            open={!!editingAvailability}
            onOpenChange={() => setEditingAvailability(null)}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Edit Availability</DialogTitle>
              </DialogHeader>
              <UpdateAvailabilityForm
                availability={editingAvailability}
                onSubmit={handleUpdateAvailability}
                onCancel={() => setEditingAvailability(null)}
              />
            </DialogContent>
          </Dialog>
        )}

        {deletingAvailability && (
          <DeleteConfirmationModal
            availability={deletingAvailability}
            onConfirm={() => setDeletingAvailability(null)}
            onCancel={() => setDeletingAvailability(null)}
          />
        )}
      </CardContent>
    </Card>
  );
};
