import { zodResolver } from "@hookform/resolvers/zod";
import { Pencil, Plus, Trash2 } from "lucide-react";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { useToast } from "../../../hooks/use-toast";
import { DealDetail } from "../../../models/deal.model";
import {
  useCreateDealDetailMutation,
  useDeleteDealDetailMutation,
  useGetDealDetailsQuery,
} from "../../../services/deal.api";
import { DeleteDialog } from "../DeleteDiaglog";

const detailSchema = z.object({
  id: z.string().optional(),
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  inclusions: z
    .array(
      z.object({
        name: z.string().min(1, "Inclusion can't be empty"),
      })
    )
    .optional(),
  exclusions: z
    .array(
      z.object({
        name: z.string().min(1, "Exclusion can't be empty"),
      })
    )
    .optional(),
});

interface DetailsTableProps {
  dealId: string;
}

export function DetailsTab({ dealId }: DetailsTableProps) {
  const { toast } = useToast();
  const { data } = useGetDealDetailsQuery(dealId);
  const [createDealDetail] = useCreateDealDetailMutation();
  const [deleteDealDetail] = useDeleteDealDetailMutation();
  const [isAddDetailOpen, setIsAddDetailOpen] = useState(false);
  const [editingDetail, setEditingDetail] = useState<DealDetail | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<string | null>(null);

  const handleOpenDialog = (id: string) => {
    setSelectedDealId(id);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedDealId(null);
  };

  const handleDeleteConfirm = () => {
    if (selectedDealId) {
      handleDeleteDetail(selectedDealId);
      setIsDialogOpen(false);
    }
  };

  const handleAddDetail = async (newDetail: Omit<DealDetail, "id">) => {
    try {
      await createDealDetail({ ...newDetail, dealId });
      toast({
        title: "Success",
        description: "Detail added successfully.",
        variant: "success",
      });
      setIsAddDetailOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while adding the detail.",
        variant: "destructive",
      });
    }
  };

  const handleUpdateDetail = (updatedDetail: DealDetail) => {
    setEditingDetail(null);
  };

  const handleDeleteDetail = async (id: string) => {
    try {
      await deleteDealDetail({ id, dealId });
      toast({
        title: "Success",
        description: "Detail deleted successfully.",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while deleting the detail.",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Additional Details</h2>
        <Dialog open={isAddDetailOpen} onOpenChange={setIsAddDetailOpen}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="w-4 h-4 mr-2" /> Add Detail
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Detail</DialogTitle>
              <DialogDescription>
                Enter the title and content for the new detail.
              </DialogDescription>
            </DialogHeader>
            <DetailForm onSubmit={handleAddDetail} />
          </DialogContent>
        </Dialog>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Title</TableHead>
            <TableHead>Content</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.data?.map((detail) => (
            <TableRow key={detail.id}>
              <TableCell>{detail.title}</TableCell>
              <TableCell>{detail.description}</TableCell>
              <TableCell className="text-right">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setEditingDetail(detail)}
                >
                  <Pencil className="w-4 h-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleOpenDialog(detail.id)}
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editingDetail && (
        <Dialog
          open={!!editingDetail}
          onOpenChange={() => setEditingDetail(null)}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Detail</DialogTitle>
              <DialogDescription>
                Update the title and content for this detail.
              </DialogDescription>
            </DialogHeader>
            <DetailForm detail={editingDetail} onSubmit={handleUpdateDetail} />
          </DialogContent>
        </Dialog>
      )}
      <DeleteDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleDeleteConfirm}
        title="Delete Deal"
        description="Are you sure you want to delete this deal detail? This action cannot be undone."
      />
    </div>
  );
}

type DetailFormValues = z.infer<typeof detailSchema>;

interface DetailFormProps {
  detail?: DealDetail;
  onSubmit: (detail: DealDetail) => void;
}
function DetailForm({ detail, onSubmit }: DetailFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DetailFormValues>({
    resolver: zodResolver(detailSchema),
    defaultValues: {
      id: detail?.id || "",
      title: detail?.title || "",
      description: detail?.description || "",
      inclusions:
        detail?.inclusions?.map((inclusion) => {
          return { name: inclusion };
        }) || [],
      exclusions:
        detail?.exclusions?.map((exclusion) => {
          return { name: exclusion };
        }) || [],
    },
  });

  const {
    fields: inclusionFields,
    append: appendInclusion,
    remove: removeInclusion,
  } = useFieldArray({
    control,
    name: "inclusions",
  });

  const {
    fields: exclusionFields,
    append: appendExclusion,
    remove: removeExclusion,
  } = useFieldArray({
    control,
    name: "exclusions",
  });

  // Custom submit handler to convert objects to strings
  const submitHandler = (data: DetailFormValues) => {
    const formattedData: DealDetail = {
      ...data,
      id: "",
      inclusions: data?.inclusions?.map((inc) => inc.name),
      exclusions: data?.exclusions?.map((exc) => exc.name),
    };
    onSubmit(formattedData);
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)} className="space-y-4">
      <div>
        <Label htmlFor="title">Title</Label>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input {...field} id="title" placeholder="Enter detail title" />
          )}
        />
        {errors.title && <p className="text-red-500">{errors.title.message}</p>}
      </div>

      <div>
        <Label htmlFor="description">Description</Label>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="description"
              placeholder="Enter description"
            />
          )}
        />
      </div>

      <div>
        <Label>Inclusions</Label>
        {inclusionFields.map((field, index) => (
          <div key={field.id} className="flex items-center gap-2">
            <Controller
              name={`inclusions.${index}.name`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={`Inclusion ${index + 1}`}
                  value={field.value || ""}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />

            <Button
              variant="destructive"
              size="sm"
              onClick={() => removeInclusion(index)}
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        ))}
        <Button
          variant="ghost"
          size="sm"
          onClick={() => appendInclusion({ name: "" })}
        >
          <Plus className="w-4 h-4 mr-2" /> Add Inclusion
        </Button>
      </div>

      <div>
        <Label>Exclusions</Label>
        {exclusionFields.map((field, index) => (
          <div key={field.id} className="flex items-center gap-2">
            <Controller
              name={`exclusions.${index}.name`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={`Exclusion ${index + 1}`}
                  value={field.value || ""}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />

            <Button
              variant="destructive"
              size="sm"
              onClick={() => removeExclusion(index)}
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        ))}
        <Button
          variant="ghost"
          size="sm"
          onClick={() => appendExclusion({ name: "" })}
        >
          <Plus className="w-4 h-4 mr-2" /> Add Exclusion
        </Button>
      </div>

      <DialogFooter>
        <Button type="submit">{detail ? "Update Detail" : "Add Detail"}</Button>
      </DialogFooter>
    </form>
  );
}
