import {
  BarChart2,
  Building,
  Calendar,
  DollarSign,
  Gift,
  Image,
  LayoutDashboard,
  LogOut,
  Mail,
  MapPin,
  Menu,
  MessageSquare,
  Music,
  ShieldCheck,
  ShoppingBag,
  Tag,
  FileUser,
  Ticket,
  UserPlus,
  Users,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { FaHandshake } from "react-icons/fa";
import ReactModal from "react-modal";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useAppSelector } from "./app/hooks";
import "./assets/css/clash-display.css";
import { Avatar, AvatarFallback, AvatarImage } from "./components/ui/avatar";
import { Button } from "./components/ui/button";
import { ScrollArea } from "./components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./components/ui/sheet";
import { Toaster } from "./components/ui/toaster";
import { authSelect } from "./features/Auth/authSlice";
import { UpdateProduct } from "./features/Product";
import { cn } from "./lib/utils";
import {
  ArtistInquiryPage,
  BrandPage,
  CarouselPage,
  CategoryPage,
  ConversationPage,
  DashboardPage,
  EmailMarketing,
  EventGroupPage,
  EventPage,
  EventTicketPage,
  GenrePage,
  GroupPage,
  OrderPage,
  OrganizationPage,
  PerformerPage,
  PermissionPage,
  ProductPage,
  ResellPage,
  RolePage,
  SelectedConversationPage,
  SelectedEventGroup,
  SelectedEventPage,
  SelectedOrderPage,
  SelectedOrganizationPage,
  SigninPage,
  SignupPage,
  SongPage,
  SplashScreen,
  TicketClaimsPage,
  TicketPage,
  UpdateEventPage,
  UpdateGroupPage,
  UpdatePerformerPage,
  UserPage,
  VenuePage,
  ViewPerformerPage,
  VoucherPage,
  WithdrawPage,
} from "./pages";
import DealsPage from "./pages/Deals";
import CreateDealPage from "./pages/Deals/CreateDeal";
import DealList from "./pages/Deals/DealList";
import { EditDealPage } from "./pages/Deals/EditDeal";
import VariantsPage from "./pages/Deals/EditDeal/DealVariant";
import SelectedDealVariantPage from "./pages/Deals/EditDeal/DealVariant/SelectedDealVariant";
import SelectedVenuePage from "./pages/Venue/SelectedVenue";
import { SellerApplicationPage } from "./pages/SellerApplication";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
}

interface LocationState {
  from?: string;
}

interface ComponentProps {
  children?: JSX.Element | JSX.Element[];
}

type ExtendentComponentProps = ProtectedRouteProps & ComponentProps;

const ProtectedRoute: React.FC<ExtendentComponentProps> = ({
  isAllowed,
  redirectPath = "/",
  children,
}): any => {
  const location = useLocation();
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

const menuSections = [
  {
    title: "MAIN",
    items: [
      { icon: LayoutDashboard, label: "Dashboard", to: "/" },
      { icon: BarChart2, label: "Brand", to: "/brand" },
      { icon: MessageSquare, label: "Conversation", to: "/conversation" },
      { icon: Image, label: "Carousel", to: "/carousel" },
    ],
  },
  {
    title: "MANAGEMENT",
    items: [
      { icon: ShoppingBag, label: "Product", to: "/product" },
      { icon: Tag, label: "Category", to: "/category" },
      { icon: Mail, label: "Email Marketing", to: "/email-marketing" },
      { icon: Calendar, label: "Event", to: "/event" },
      { icon: Calendar, label: "Event Group", to: "/event-group" },
      { icon: MapPin, label: "Venue", to: "/venues" },
      { icon: UserPlus, label: "Artist Inquiry", to: "/artist-inquiry" },
    ],
  },
  {
    title: "PERFORMERS",
    items: [
      { icon: Music, label: "Performer", to: "/performer" },
      { icon: Users, label: "Group", to: "/group" },
      { icon: Music, label: "Song", to: "/song" },
      { icon: Music, label: "Genre", to: "/genre" },
    ],
  },
  {
    title: "TICKETS & ORDERS",
    items: [
      { icon: Ticket, label: "Ticket", to: "/ticket" },
      { icon: ShieldCheck, label: "Ticket Claims", to: "/ticket-claims" },
      { icon: ShoppingBag, label: "Order", to: "/order" },
      { icon: DollarSign, label: "Resell", to: "/resell" },
    ],
  },
  {
    title: "Deals",
    items: [{ icon: FaHandshake, label: "Deal", to: "/deals" }],
  },
  {
    title: "USERS & PERMISSIONS",
    items: [
      { icon: Users, label: "User", to: "/user" },
      { icon: ShieldCheck, label: "Role", to: "/role" },
      { icon: ShieldCheck, label: "Permission", to: "/permission" },
      { icon: Building, label: "Organization", to: "/organization" },
    ],
  },
  {
    title: "OTHER",
    items: [
      { icon: Gift, label: "Voucher", to: "/voucher" },
      { icon: DollarSign, label: "Withdraw", to: "/withdraw" },
      {
        icon: FileUser,
        label: "Seller Application",
        to: "/seller-application",
      },
    ],
  },
];

ReactModal.setAppElement("#root");

const App = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const { user } = useAppSelector(authSelect);
  const [splashScreen, setSplashScreen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setSplashScreen(true);
    setTimeout(() => {
      setSplashScreen(false);
    }, 2000);
  }, []);

  const toggleSidebar = () => setIsExpanded(!isExpanded);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  if (splashScreen) return <SplashScreen />;

  return (
    <div id="app" className="flex h-screen overflow-hidden">
      <Routes>
        <Route
          element={
            <ProtectedRoute
              isAllowed={user === null}
              redirectPath={state?.from || "/"}
            />
          }
        >
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Route>
        <Route
          element={
            <div className="flex flex-grow w-full">
              {/* Sidebar for desktop */}
              <aside
                className={cn(
                  "hidden md:flex flex-col w-64 bg-blue-1100 text-primary-foreground",
                  isExpanded ? "w-64" : "w-20"
                )}
              >
                <div className="flex items-center justify-between p-4 border-b border-primary/10">
                  {isExpanded && (
                    <div className="flex items-center space-x-2">
                      <Avatar>
                        <AvatarImage src="/logo.png" alt="Ticketnation" />
                        <AvatarFallback>TN</AvatarFallback>
                      </Avatar>
                      <span className="text-lg font-semibold">
                        Ticketnation
                      </span>
                    </div>
                  )}
                  <Button variant="ghost" size="icon" onClick={toggleSidebar}>
                    {isExpanded ? <LayoutDashboard /> : <Menu />}
                  </Button>
                </div>
                <ScrollArea className="flex-1">
                  <nav className="p-4">
                    {menuSections.map((section, index) => (
                      <div key={index} className="mb-6">
                        {isExpanded && (
                          <h2 className="mb-2 text-xs font-semibold text-primary-foreground/60">
                            {section.title}
                          </h2>
                        )}
                        <ul className="space-y-1">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <Button
                                variant={
                                  location.pathname === item.to
                                    ? "secondary"
                                    : "ghost"
                                }
                                className="justify-start w-full"
                                asChild
                              >
                                <Link
                                  to={item.to}
                                  className={` ${
                                    isExpanded
                                      ? "p-4"
                                      : "flex items-center justify-center !p-0"
                                  }`}
                                >
                                  {React.createElement(item?.icon, {
                                    className: "w-4 h-4 mr-2",
                                  })}
                                  {isExpanded && <span>{item.label}</span>}
                                </Link>
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </nav>
                </ScrollArea>
                <div className="p-4 border-t border-primary/10">
                  <div className="flex items-center mb-4 space-x-4">
                    <Avatar>
                      <AvatarFallback>
                        {user?.firstName.charAt(0)}
                        {user?.lastName.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                    {isExpanded && (
                      <div>
                        <p className="font-semibold">{`${user?.firstName} ${user?.lastName}`}</p>
                        <p className="text-sm text-primary-foreground/60">
                          {user?.email}
                        </p>
                      </div>
                    )}
                  </div>
                  <Button
                    variant="secondary"
                    className="w-full"
                    onClick={() => {
                      /* Handle logout */
                    }}
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    {isExpanded && "Logout"}
                  </Button>
                </div>
              </aside>
              {/* Main content area */}
              <div className="flex flex-col flex-1 overflow-hidden">
                {/* Header for mobile */}
                <div className="flex flex-col md:hidden">
                  <header className="flex items-center justify-between p-4 border-b">
                    <div className="flex items-center space-x-2">
                      <Avatar>
                        <AvatarImage src="/logo.png" alt="Ticketnation" />
                        <AvatarFallback>TN</AvatarFallback>
                      </Avatar>
                      <span className="text-lg font-semibold">
                        Ticketnation
                      </span>
                    </div>
                    <Sheet>
                      <SheetTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <Menu />
                        </Button>
                      </SheetTrigger>
                      <SheetContent
                        side="left"
                        className="flex flex-col w-64 p-0 bg-white"
                      >
                        <SheetHeader className="p-4 border-b">
                          <SheetTitle>Menu</SheetTitle>
                        </SheetHeader>
                        <ScrollArea className="flex-1 ">
                          <nav className="p-4">
                            {menuSections.map((section, index) => (
                              <div key={index} className="mb-6">
                                <h2 className="mb-2 text-xs font-semibold text-muted-foreground">
                                  {section.title}
                                </h2>
                                <ul className="space-y-1">
                                  {section.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                      <Button
                                        variant={
                                          location.pathname === item.to
                                            ? "secondary"
                                            : "ghost"
                                        }
                                        className="justify-start w-full"
                                        asChild
                                      >
                                        <Link to={item.to}>
                                          {React.createElement(item?.icon, {
                                            className: "w-4 h-4 mr-2",
                                          })}
                                          <span>{item.label}</span>
                                        </Link>
                                      </Button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </nav>
                        </ScrollArea>
                        <div className="p-4 border-t border-primary/10">
                          <div className="flex items-center mb-4 space-x-4">
                            <Avatar>
                              <AvatarFallback>
                                {user?.firstName.charAt(0)}
                                {user?.lastName.charAt(0)}
                              </AvatarFallback>
                            </Avatar>
                            {isExpanded && (
                              <div>
                                <p className="font-semibold">{`${user?.firstName} ${user?.lastName}`}</p>
                                <p className="text-sm text-primary-foreground/60">
                                  {user?.email}
                                </p>
                              </div>
                            )}
                          </div>
                          <Button
                            variant="secondary"
                            className="w-full"
                            onClick={() => {
                              /* Handle logout */
                            }}
                          >
                            <LogOut className="w-4 h-4 mr-2" />
                            {isExpanded && "Logout"}
                          </Button>
                        </div>
                      </SheetContent>
                    </Sheet>
                  </header>
                </div>

                {/* Main content */}
                <main className="flex-1 overflow-x-hidden overflow-y-auto ">
                  <Outlet />
                </main>
              </div>
            </div>
          }
        >
          <Route
            element={
              <ProtectedRoute
                isAllowed={user !== null}
                redirectPath="/signin"
              />
            }
          >
            <Route path="/" element={<DashboardPage />} />
            <Route path="brand" element={<BrandPage />} />
            <Route path="deals" element={<DealsPage />}>
              <Route index element={<DealList />} />
              <Route path=":id" element={<EditDealPage />} />
              <Route path=":id/variants">
                <Route index element={<VariantsPage />} />
                <Route path=":variantId">
                  <Route index element={<SelectedDealVariantPage />} />
                </Route>
              </Route>
              <Route path="create" element={<CreateDealPage />} />
            </Route>
            <Route path="conversation">
              <Route index element={<ConversationPage />} />
              <Route
                path=":conversationId"
                element={<SelectedConversationPage />}
              />
            </Route>
            <Route path="email-marketing" element={<EmailMarketing />} />
            <Route path="event">
              <Route index element={<EventPage />} />
              <Route path=":eventId" element={<SelectedEventPage />} />
              <Route path=":eventId/edit" element={<UpdateEventPage />} />
              <Route path=":eventId/ticket" element={<EventTicketPage />} />
            </Route>
            <Route path="event-group">
              <Route index element={<EventGroupPage />} />
              <Route path=":eventGroupId" element={<SelectedEventGroup />} />
            </Route>
            <Route path="category" element={<CategoryPage />} />
            <Route path="carousel" element={<CarouselPage />} />
            <Route path="venues">
              <Route path=":id" element={<SelectedVenuePage />} />
              <Route index element={<VenuePage />} />
            </Route>

            <Route path="resell" element={<ResellPage />} />
            <Route path="ticket" element={<TicketPage />} />
            <Route path="ticket-claims">
              <Route index element={<TicketClaimsPage />} />
              <Route path=":id" element={<TicketClaimsPage />} />
            </Route>
            <Route path="organization">
              <Route index element={<OrganizationPage />} />
              <Route path=":id/view" element={<SelectedOrganizationPage />} />
            </Route>
            <Route path="order">
              <Route index element={<OrderPage />} />
              <Route path=":orderId/view" element={<SelectedOrderPage />} />
            </Route>
            <Route path="performer">
              <Route index element={<PerformerPage />} />
              <Route path=":id" element={<ViewPerformerPage />} />
              <Route path=":id/edit" element={<UpdatePerformerPage />} />
            </Route>
            <Route path="artist-inquiry" element={<ArtistInquiryPage />} />
            <Route path="group">
              <Route index element={<GroupPage />} />
              <Route path=":id/edit" element={<UpdateGroupPage />} />
            </Route>
            <Route path="song" element={<SongPage />} />
            <Route path="genre" element={<GenrePage />} />
            <Route path="product">
              <Route index element={<ProductPage />} />
              <Route path=":id/edit" element={<UpdateProduct />} />
            </Route>
            <Route path="voucher" element={<VoucherPage />} />
            <Route path="user" element={<UserPage />} />
            <Route path="role" element={<RolePage />} />
            <Route path="permission" element={<PermissionPage />} />
            <Route path="withdraw" element={<WithdrawPage />} />
            <Route
              path="seller-application"
              element={<SellerApplicationPage />}
            />
          </Route>
        </Route>
      </Routes>
      <Toaster />
    </div>
  );
};

export default App;
