import { zodResolver } from "@hookform/resolvers/zod";
import { PopoverContent } from "@radix-ui/react-popover";
import { format } from "date-fns"; // Assuming you are using date-fns for date formatting
import { CalendarIcon } from "lucide-react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { z, infer as zInfer } from "zod";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Checkbox } from "../../../components/ui/checkbox";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Popover, PopoverTrigger } from "../../../components/ui/popover";
import { CategorySelectV2 } from "../../../features/Category/CategorySelect/CategorySelectV2";
import { useToast } from "../../../hooks/use-toast";
import { cn } from "../../../lib/utils";
import { CategoryType } from "../../../models/category.model";
import { useCreateDealMutation } from "../../../services/deal.api";
import OrganizationSelect from "./OrganizationSelect";

// Zod schema for validation
const dealDetailsSchema = z.object({
  title: z.string().min(1, "Detail title is required"),
  description: z.string().min(1, "Detail description is required"),
  inclusions: z.boolean(),
  exclusions: z.boolean(),
});

const dealVariantSchema = z.object({
  variantName: z.string().min(1, "Variant name is required"),
  price: z.number().min(0, "Price should be a positive number"),
  discountValue: z
    .number()
    .min(0, "Discount value should be a positive number"),
  availability: z.number().min(1, "Availability should be at least 1"),
  isPublished: z.boolean(),
});

export const dealSchema = z.object({
  name: z.string().min(1, "Deal name is required"),
  description: z.string().min(1, "Description is required"),
  categoryId: z.string().min(1, "Category is required"),
  organizationId: z.string().min(1, "Organization is required"),
  startDate: z.date({ required_error: "Start date is required" }),
  endDate: z.date({ required_error: "End date is required" }),
  isFeatured: z.boolean(),
  isPublished: z.boolean(),
});

// Infer the TypeScript type from the Zod schema
type DealFormValues = zInfer<typeof dealSchema>;

export const CreateDealPage: React.FC = () => {
  const { toast } = useToast();
  const [createDeal, { data }] = useCreateDealMutation();
  const methods = useForm<DealFormValues>({
    resolver: zodResolver(dealSchema),
    defaultValues: {
      name: "",
      description: "",
      categoryId: "",
      organizationId: "",
      startDate: new Date(),
      endDate: new Date(),
      isFeatured: false,
      isPublished: false,
    },
  });

  const onSubmit: SubmitHandler<DealFormValues> = async (data) => {
    try {
      await createDeal(data);
      toast({
        title: "Success",
        description: "Deal created successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create deal",
        variant: "destructive",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Card>
        <CardHeader>
          <CardTitle>Create New Deal</CardTitle>
          <CardDescription>
            Enter the details for your new deal.
          </CardDescription>
        </CardHeader>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="name">Deal Name</Label>
              <Controller
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <Input {...field} placeholder="Enter deal name" />
                )}
              />
              {methods.formState.errors.name && (
                <p className="text-red-500">
                  {methods.formState.errors.name.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Controller
                name="description"
                control={methods.control}
                render={({ field }) => (
                  <Input {...field} placeholder="Enter deal description" />
                )}
              />
              {methods.formState.errors.description && (
                <p className="text-red-500">
                  {methods.formState.errors.description.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="category">Category</Label>
              {/* <Controller
                name="category"
                control={methods.control}
                render={({ field }) => (
                  <Input {...field} placeholder="Enter deal category" />
                )}
              /> */}
              <CategorySelectV2
                name="categoryId"
                type={CategoryType.DEAL}
                hasParent={false}
              />
              {methods.formState.errors.categoryId && (
                <p className="text-red-500">
                  {methods.formState.errors.categoryId.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="organizationId">Organization</Label>
              <OrganizationSelect name="organizationId" />
              {methods.formState.errors.organizationId && (
                <p className="text-red-500">
                  {methods.formState.errors.organizationId.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <Label>Deal Period</Label>
              <div className="flex space-x-4">
                <div>
                  <Label htmlFor="startDate">Start Date</Label>
                  <Controller
                    name="startDate"
                    control={methods.control}
                    render={({ field }) => (
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-[280px] justify-start text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            <CalendarIcon className="w-4 h-4 mr-2" />
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                    )}
                  />
                  {methods.formState.errors.startDate && (
                    <p className="text-red-500">
                      {methods.formState.errors.startDate.message}
                    </p>
                  )}
                </div>
                <div>
                  <Label htmlFor="endDate">End Date</Label>
                  <Controller
                    name="endDate"
                    control={methods.control}
                    render={({ field }) => (
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-[280px] justify-start text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            <CalendarIcon className="w-4 h-4 mr-2" />
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                    )}
                  />
                  {methods.formState.errors.endDate && (
                    <p className="text-red-500">
                      {methods.formState.errors.endDate.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <Controller
                name="isFeatured"
                control={methods.control}
                render={({ field }) => (
                  <>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <Label htmlFor="isFeatured">Feature this deal</Label>
                  </>
                )}
              />
            </div>
            <div className="flex items-center space-x-2">
              <Controller
                name="isPublished"
                control={methods.control}
                render={({ field }) => (
                  <>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <Label htmlFor="isPublished">Publish this deal</Label>
                  </>
                )}
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button type="submit">Create Deal</Button>
          </CardFooter>
        </form>
      </Card>
    </FormProvider>
  );
};

export default CreateDealPage;
