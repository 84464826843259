import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Input, Label } from "../../../components/common";
import {
  CustomError,
  useGetResellQuery,
  useUpdateResellMutation,
} from "../../../services/resell.api"; // Replace with your TnResell API service
import EventSelect from "../EventSelect";

// Define the form values type
interface FormValues {
  eventId: string | null;
  eventName: string;
  eventDate: string;
  eventTime: string;
  venueName: string;
  section: string;
  row: string;
  quantity: number;
  lowSeat: number;
  highSeat: number;
  pricePerTicket: number;
  status: "pending" | "completed" | "rejected";
  notes: string;
}

interface ComponentProps {
  resellId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateResell: React.FC<ComponentProps> = ({ setOpen, resellId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateResellMutation();

  const { data: resell, isLoading: resellIsLoading } =
    useGetResellQuery(resellId);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (resell) {
      const { eventId, ...newValues } = values;

      console.log("newValues", newValues);
      console.log("newValues values", values);
      console.log("newValues eventId", eventId);

      update({
        id: resell.id,
        eventId: eventId === "-- Select Event --" ? null : eventId,
        ...newValues,
      });
    }
  };

  if (isLoading || resellIsLoading) return <h2>Loading...</h2>;
  if (!resell) return <h2>Something went wrong...</h2>;

  const initialValues: FormValues = {
    eventId: resell?.eventId || null,
    eventName: resell?.eventName || "",
    eventDate: resell?.eventDate || "",
    eventTime: resell?.eventTime || "",
    venueName: resell?.venueName || "",
    section: resell?.section || "",
    row: resell?.row || "",
    quantity: resell?.quantity || 0,
    lowSeat: resell?.lowSeat || 0,
    highSeat: resell?.highSeat || 0,
    pricePerTicket: resell?.pricePerTicket || 0,
    status: resell?.status || "pending", // Set default status as 'pending'
    notes: resell?.notes || "",
  };

  const validationSchema = Yup.object().shape({
    eventId: Yup.string().optional().nullable(),
    eventName: Yup.string().required("Event Name is required."),
    eventDate: Yup.string().required("Event Date is required."),
    eventTime: Yup.string().required("Event Time is required."),
    venueName: Yup.string().required("Venue Name is required."),
    section: Yup.string().required("Section is required."),
    row: Yup.string().required("Row is required."),
    quantity: Yup.number().required("Quantity is required.").min(1),
    lowSeat: Yup.number().required("Low Seat is required.").min(1),
    highSeat: Yup.number().required("High Seat is required.").min(1),
    pricePerTicket: Yup.number()
      .required("Price Per Ticket is required.")
      .min(0.01),
    status: Yup.string().required("Status is required."),
    notes: Yup.string().optional(),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map(
                      (message, index) => (
                        <li key={index}>{message}</li>
                      )
                    )}
                  </ul>
                </div>
              )}

              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="eventId">Event</Label>
                <Field name="eventId">
                  {({ field }: FieldProps) => (
                    <EventSelect {...field} className="w-full" />
                  )}
                </Field>
                <Label htmlFor="eventName">Event Name</Label>
                <Field name="eventName">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Event Name" id="eventName" {...field} />
                  )}
                </Field>
                <Label htmlFor="eventDate">Event Date</Label>
                <Field name="eventDate">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Event Date" id="eventDate" {...field} />
                  )}
                </Field>
                <Label htmlFor="eventTime">Event Time</Label>
                <Field name="eventTime">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Event Time" id="eventTime" {...field} />
                  )}
                </Field>
                <Label htmlFor="venueName">Venue Name</Label>
                <Field name="venueName">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Venue Name" id="venueName" {...field} />
                  )}
                </Field>
                <Label htmlFor="section">Section</Label>
                <Field name="section">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Section" id="section" {...field} />
                  )}
                </Field>
                <Label htmlFor="row">Row</Label>
                <Field name="row">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Row" id="row" {...field} />
                  )}
                </Field>
                <Label htmlFor="quantity">Quantity</Label>
                <Field name="quantity">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Quantity"
                      id="quantity"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="lowSeat">Low Seat</Label>
                <Field name="lowSeat">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Low Seat"
                      id="lowSeat"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="highSeat">High Seat</Label>
                <Field name="highSeat">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="High Seat"
                      id="highSeat"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="pricePerTicket">Price Per Ticket</Label>
                <Field name="pricePerTicket">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Price Per Ticket"
                      id="pricePerTicket"
                      {...field}
                    />
                  )}
                </Field>

                {/* Status Field */}
                <Label htmlFor="status">Status</Label>
                <Field name="status" as="select">
                  {({ field }: FieldProps) => (
                    <select
                      id="status"
                      {...field}
                      className="p-2 border rounded"
                    >
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  )}
                </Field>

                {/* Notes Field */}
                <Label htmlFor="notes">Notes</Label>
                <Field name="notes">
                  {({ field }: FieldProps) => (
                    <textarea
                      id="notes"
                      placeholder="Add any additional notes here"
                      className="p-2 border rounded"
                      {...field}
                    />
                  )}
                </Field>
              </div>

              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateResell;
