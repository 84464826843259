import { zodResolver } from "@hookform/resolvers/zod";
import { InfoIcon } from "lucide-react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../../../../components/ui/button";
import { CardContent, CardFooter } from "../../../../../../components/ui/card";
import { Input } from "../../../../../../components/ui/input";
import { Label } from "../../../../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";
import { AvailabilityType } from "../../../../../../models/deal.model";

// Zod validation schema
export const createAvailabilitySchema = z
  .object({
    availabilityType: z.nativeEnum(AvailabilityType, {
      required_error: "Availability type is required",
    }),
    date: z.string().optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
    time: z.string().optional(),
    price: z
      .number({ invalid_type_error: "Price must be a number" })
      .min(0, { message: "Price must be non-negative" })
      .optional(),
    quantity: z
      .number({ invalid_type_error: "Quantity must be a number" })
      .min(1, { message: "Quantity must be at least 1" })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.availabilityType === AvailabilityType.SPECIFIC && !data.date) {
      ctx.addIssue({
        code: "custom",
        path: ["date"],
        message: "Date is required for Specific Date type",
      });
    }

    if (data.availabilityType === AvailabilityType.RANGE) {
      if (!data.startDate) {
        ctx.addIssue({
          code: "custom",
          path: ["startDate"],
          message: "Start date is required for Date Range type",
        });
      }
      if (!data.endDate) {
        ctx.addIssue({
          code: "custom",
          path: ["endDate"],
          message: "End date is required for Date Range type",
        });
      }
    }
  });

// Define form value interfaces
export interface CreateAvailabilityFormValues {
  availabilityType: AvailabilityType;
  date?: string;
  startDate?: string;
  endDate?: string;
  time?: string;
  price: number;
  quantity: number;
}

export interface UpdateAvailabilityFormValues
  extends CreateAvailabilityFormValues {
  id: string;
}

// AvailabilityForm component
interface AvailabilityFormProps {
  availabilityType?: AvailabilityType;
  defaultValues?: CreateAvailabilityFormValues | UpdateAvailabilityFormValues;
  onSubmit: (
    data: CreateAvailabilityFormValues | UpdateAvailabilityFormValues
  ) => void;
  onCancel: () => void;
}

export const AvailabilityForm: React.FC<AvailabilityFormProps> = ({
  availabilityType: defaultAvailabilityType,
  defaultValues,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateAvailabilityFormValues>({
    resolver: zodResolver(createAvailabilitySchema),
    defaultValues: {
      ...defaultValues,
      date: defaultValues?.date
        ? new Date(defaultValues.date).toISOString().split("T")[0]
        : undefined,
      startDate: defaultValues?.startDate
        ? new Date(defaultValues.startDate).toISOString().split("T")[0]
        : undefined,
      endDate: defaultValues?.endDate
        ? new Date(defaultValues.endDate).toISOString().split("T")[0]
        : undefined,
      availabilityType: defaultAvailabilityType || AvailabilityType.SPECIFIC,
    },
  });

  const availabilityType = watch("availabilityType");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent className="space-y-4">
        <div>
          <Label htmlFor="availabilityType">
            Availability Type
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoIcon className="inline-block w-4 h-4 ml-1" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Choose how this availability is applied</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <Controller
            name="availabilityType"
            control={control}
            render={({ field }) => (
              <Select
                onValueChange={(value) =>
                  field.onChange(value as AvailabilityType)
                }
                defaultValue={field.value}
                disabled
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select availability type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={AvailabilityType.SPECIFIC}>
                    Specific Date
                  </SelectItem>
                  <SelectItem value={AvailabilityType.RANGE}>
                    Date Range
                  </SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.availabilityType && (
            <p className="mt-1 text-sm text-red-500">
              {errors.availabilityType.message}
            </p>
          )}
        </div>

        {availabilityType === AvailabilityType.SPECIFIC && (
          <div>
            <Label htmlFor="date">Date</Label>
            <Input type="date" {...register("date")} />
            {errors.date && (
              <p className="mt-1 text-sm text-red-500">{errors.date.message}</p>
            )}
          </div>
        )}

        {availabilityType === AvailabilityType.RANGE && (
          <>
            <div>
              <Label htmlFor="startDate">Start Date</Label>
              <Input type="date" {...register("startDate")} />
              {errors.startDate && (
                <p className="mt-1 text-sm text-red-500">
                  {errors.startDate.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="endDate">End Date</Label>
              <Input type="date" {...register("endDate")} />
              {errors.endDate && (
                <p className="mt-1 text-sm text-red-500">
                  {errors.endDate.message}
                </p>
              )}
            </div>
          </>
        )}

        <div>
          <Label htmlFor="time">Time</Label>
          <Input type="time" {...register("time")} />
        </div>

        <div>
          <Label htmlFor="price">Price</Label>
          <Input
            type="number"
            {...register("price", { valueAsNumber: true })}
          />
          {errors.price && (
            <p className="mt-1 text-sm text-red-500">{errors.price.message}</p>
          )}
        </div>

        <div>
          <Label htmlFor="quantity">Quantity</Label>
          <Input
            type="number"
            {...register("quantity", { valueAsNumber: true })}
          />
          {errors.quantity && (
            <p className="mt-1 text-sm text-red-500">
              {errors.quantity.message}
            </p>
          )}
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">
          {defaultValues ? "Update Availability" : "Create Availability"}
        </Button>
      </CardFooter>
    </form>
  );
};

export default AvailabilityForm;
