import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select"; // Importing from shadcn/ui
import { CategoryType } from "../../../models/category.model";
import { useGetCategoriesQuery } from "../../../services/category.api";

interface ComponentProps {
  className?: string;
  name: string;
  type: CategoryType; // Add the category type prop for filtering
  hasParent?: boolean; // Optional parameter to filter categories based on parent
}

export const CategorySelectV2: React.FC<ComponentProps> = ({
  className,
  name,
  type,
  hasParent,
}) => {
  const { data: categories } = useGetCategoriesQuery({
    type,
    hasParent,
  });
  const { control } = useFormContext();

  return (
    <div className={`${className} flex flex-col`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <SelectTrigger className="py-2 border rounded">
              <SelectValue placeholder="-- Select Category --" />
            </SelectTrigger>
            <SelectContent>
              {categories?.data.map((category) => (
                <SelectItem key={category.id} value={category.id}>
                  {category.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </div>
  );
};

export default CategorySelectV2;
