import { Image } from "./image.model";

export interface Category {
  id: string;
  name: string;
  slug: string;
  description?: string;
  parentId?: string;
  type: CategoryType;
  image?: Image;
}

export enum CategoryType {
  EVENT = "EVENT",
  PRODUCT = "PRODUCT",
  DEAL = "DEAL",
}

export interface CreateCategory {
  name: string;
  description?: string;
  parentId?: string;
  type: CategoryType;
}

export interface UpdateCategory {
  id: string;
  name: string;
  slug?: string;
  description?: string;
  parentId?: string;
  type: CategoryType;
}
