import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select"; // Importing from shadcn/ui
import { Organization } from "../../../models/organization.model";
import { useGetOrganizationsQuery } from "../../../services/organization.api";

interface ComponentProps {
  className?: string;
  name: string;
}

const OrganizationSelect: React.FC<ComponentProps> = ({ className, name }) => {
  const { data: organizations } = useGetOrganizationsQuery({});
  const { control } = useFormContext();

  return (
    <div className={`${className} flex flex-col`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <SelectTrigger className="py-2 border rounded">
              <SelectValue placeholder="-- Select Organization --" />
            </SelectTrigger>
            <SelectContent>
              {organizations?.data.map((org: Organization) => (
                <SelectItem key={org.id} value={org.id}>
                  {org.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </div>
  );
};

export default OrganizationSelect;
